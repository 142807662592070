import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { getNumberWithOrdinal } from "../helpers/utility";

const display_decimal = d => {
  return parseFloat(d).toString();
};

class BarChart extends Component {
  getRandomInt = max => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  render() {
    let results = this.props.results;
    let knee_up_percentile = [];
    let push_up_percentile = [];
    let single_squat_percentile = [];
    let pull_up_percentile = [];
    let knee_up_user_index = -1;
    let pull_up_user_index = -1;
    let single_squat_user_index_l = -1;
    let single_squat_user_index_r = -1;
    let pull_up_user_value = "";
    let knee_up_user_value = "";
    let single_squat_user_value_l = "";
    let single_squat_user_value_r = "";
    let push_up_user_value = "";
    let push_up_user_index = -1;

    let pointRadius = [];
    let pointRadius2 = [];
    let pointRadius3 = [];
    let pointRadius4 = [];
    let labels = [];
    for (let i = 0; i < 100; i++) {
      pointRadius[i] = 0;
      labels[i] = i;
      pointRadius2[i] = 0;
      pointRadius3[i] = 0;
      pointRadius4[i] = 0;
    }

    let pr2 = this.getRandomInt(75);
    let pr2b = this.getRandomInt(75);
    let pr3 = this.getRandomInt(75);

    if (results.knee_up_percentile) {
      knee_up_percentile = JSON.parse(results.knee_up_percentile);
      knee_up_user_index = results.knee_up_user_index;
      pointRadius[knee_up_user_index] = 5;
      knee_up_user_value = results.knee_up_user_value;
    }

    if (results.pull_up_percentile) {
      pull_up_percentile = JSON.parse(results.pull_up_percentile);
      pull_up_user_index = results.pull_up_user_index;
      pointRadius2[pull_up_user_index] = 5;
      pull_up_user_value = results.pull_up_user_value;
    }
    if (results.single_squat_percentile) {
      single_squat_percentile = JSON.parse(results.single_squat_percentile);
      single_squat_user_index_l = results.single_squat_user_index_l;
      single_squat_user_index_r = results.single_squat_user_index_r;
      pointRadius3[single_squat_user_index_l] = 5;
      pointRadius3[single_squat_user_index_r] = 5;
      single_squat_user_value_l = results.single_squat_user_value_l;
      single_squat_user_value_r = results.single_squat_user_value_r;
    }
    if (results.push_up_percentile) {
      push_up_percentile = JSON.parse(results.push_up_percentile);
      push_up_user_index = results.push_up_user_index;
      pointRadius4[push_up_user_index] = 5;
      push_up_user_value = results.push_up_user_value;
    }

    let data_labels = [
      `Knee Ups - ${
        knee_up_user_value ? display_decimal(knee_up_user_value) + ", " : ""
      }${
        knee_up_user_index
          ? getNumberWithOrdinal(knee_up_user_index) + "%"
          : "not reported"
      }`,
      `Pull Ups - ${
        pull_up_user_value ? display_decimal(pull_up_user_value) + ", " : ""
      }${
        pull_up_user_index
          ? getNumberWithOrdinal(pull_up_user_index) + "%"
          : "not reported"
      }`,
      `Single Leg Squats - L: ${
        single_squat_user_value_l
          ? display_decimal(single_squat_user_value_l) + ", "
          : ""
      }${
        single_squat_user_index_l
          ? getNumberWithOrdinal(single_squat_user_index_l) + "%"
          : "not reported"
      } & R: ${
        single_squat_user_value_r
          ? display_decimal(single_squat_user_value_r) + ", "
          : ""
      }${
        single_squat_user_index_r
          ? getNumberWithOrdinal(single_squat_user_index_r) + "%"
          : "not reported"
      }`,
      `Push Ups - ${
        push_up_user_value ? display_decimal(push_up_user_value) + ", " : ""
      }${
        push_up_user_index
          ? getNumberWithOrdinal(push_up_user_index) + "%"
          : "not reported"
      }`
    ];
    // console.log("this state reps", this.props.results);
    return (
      <Line
        data={{
          labels: labels,
          datasets: [
            {
              label: data_labels[0],
              data: knee_up_percentile,
              pointStyle: "rectRounded",
              pointRadius: pointRadius,
              pointStyle: "circle",
              fill: false,
              backgroundColor: "red",
              borderColor: "red",
              borderWidth: 1,
              lineTension: 1
            },
            {
              label: data_labels[1],
              data: pull_up_percentile,
              pointStyle: "rectRounded",
              pointRadius: pointRadius2,
              pointStyle: "circle",
              fill: false,
              backgroundColor: "#243e4c",
              borderColor: "#243e4c",
              borderWidth: 1,
              lineTension: 1
            },
            {
              label: data_labels[2],
              data: single_squat_percentile,
              pointStyle: "rectRounded",
              pointRadius: pointRadius3,
              pointStyle: "circle",
              fill: false,
              backgroundColor: "#ccb637",
              borderColor: "#ccb637",
              borderWidth: 1,
              lineTension: 1
            },
            {
              label: data_labels[3],
              data: push_up_percentile,
              pointStyle: "rectRounded",
              pointRadius: pointRadius4,
              pointStyle: "circle",
              fill: false,
              backgroundColor: "#9fcc3b",
              borderColor: "#9fcc3b",
              borderWidth: 1,
              lineTension: 1
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          aspectRatio: 1,
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  // Include a dollar sign in the ticks
                  callback: function(value, index, values) {
                    if ([0, 25, 50, 75, 99].includes(index)) return index;
                    if (
                      [
                        knee_up_user_index,
                        pull_up_user_index,
                        single_squat_user_index_l,
                        single_squat_user_index_r
                      ].includes(index)
                    )
                      return "";
                    else return null;
                  }
                }
              }
            ]
          },
          legend: {
            position: "bottom",
            display: true,
            labels: {
              fontColor: "rgb(0,0,0)"
            },
            labels: {
              boxWidth: 20
            },
            align: "start"
          },
          title: {
            display: true,
            text: this.props.title
          },
          tooltips: {
            enabled: true,
            callbacks: {
              title: () => "",
              label: (tooltipItem, data) =>
                data.datasets[tooltipItem.datasetIndex].label
            }
          }
        }}
      />
    );
  }
}

export default BarChart;
