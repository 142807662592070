import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-solid-svg-icons";

import * as requests from "./helpers/requests";
import { api_url } from "./environment";

import { CopyToClipboard } from "react-copy-to-clipboard";

import "./index.css";

import Header from "./header";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      confirmed: false,
      consent1: false,
      copy_text: "COPY LINK"
    };
  }

  componentDidMount() {
    this.survey = this.props.survey;

    //block users from hitting this page without an email
    if (!this.survey.id) this.props.history.push("/");
    if (this.survey.email_validated) {
      this.confirm(this.props.email);
    }
    this.setState({ value: this.props.email });
  }

  confirm = () => {
    let email = this.state.value;
    if (this.survey.email_validated) email = this.survey.email;

    let url = `${api_url}/confirm_email/`;
    let body = {
      email,
      survey_id: this.survey.id
    };
    requests.post(url, JSON.stringify(body));

    this.setState({ confirmed: true });
  };

  render() {
    let validated_email = this.survey && this.survey.email_validated;
    return (
      <div className="main">
        <Header />
        {!this.state.confirmed && !validated_email ? (
          <div className="box">
            <div className="flex">
              <div className="label margin20 t-align-c">Your Report</div>
              <div className="flex t-align-c">
                <div className="label2 width-max">
                  You're done! Your report gets emailed - please check the I
                  AGREE box below so we can send it to you. Your data may be
                  used in our collection. We may also contact you if the test
                  changes or evolves.
                </div>
                <div className="checkbox-wrapper width-70">
                  <FontAwesomeIcon
                    icon={this.state.consent1 ? faCheckSquare : faSquare}
                    className="checkbox"
                    onClick={() =>
                      this.setState({ consent1: !this.state.consent1 })
                    }
                  />
                  <div className="label2 checkbox-text">I AGREE</div>
                </div>

                <div className="label2 margin-t-20">
                  <strong>Correct email?</strong>
                </div>
                <div className="noflex">
                  <input
                    type={`${this.props.numeric ? "number" : "text"}`}
                    className="input input-100 margin-tb-20 lowercase"
                    name={this.props.name}
                    ref={inp => {
                      this._input = inp;
                    }}
                    value={this.state.value}
                    placeholder={"Enter your email"}
                    onKeyDown={this.onKeyPress}
                    onChange={e => this.setState({ value: e.target.value })}
                    onFocus={() => {
                      this.setState({ focused: true });
                    }}
                    onBlur={() =>
                      setTimeout(() => this.setState({ focused: false }), 100)
                    }
                  />

                  <div className="button-wrap margin20">
                    {this.state.consent1 ? (
                      <button className="button" onClick={this.confirm}>
                        SEND ME MY REPORT
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.confirmed && !validated_email ? (
          <div className="box">
            <div className="flex">
              <div className="label margin20 t-align-c">Thanks!</div>

              <div className="flex ">
                <div className="label2">
                  Please check your email to access your report.
                </div>

                <div className="button-wrap margin20 t-align-c">
                  <button
                    className="button"
                    onClick={() =>
                      (window.location = "https://www.howtoclimb513.com")
                    }
                  >
                    BACK HOME
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {validated_email ? (
          <div className="box">
            <div className="flex">
              <div className="label margin20 t-align-c">Thanks!</div>

              <div className="flex ">
                <div className="label2 width-max">
                  You can access your report below. Please share the test! The
                  larger the result set the more meaningful the comparisons for
                  training. You can post results to Facebook directly from your
                  results page, or email/text out a link.
                </div>

                <div className="button-wrap  t-align-c">
                  <CopyToClipboard
                    text={`https://data.howtoclimb513.com/results/${this.survey.id}`}
                    onCopy={() => this.setState({ copy_text: "COPIED" })}
                  >
                    <button className="button">{this.state.copy_text}</button>
                  </CopyToClipboard>
                </div>
                <div className="button-wrap t-align-c">
                  <a
                    href={`https://data.howtoclimb513.com/myresults/${this.survey.id}`}
                    target="_blank"
                  >
                    <button className="button">SEE MY RESULTS</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(Report);
