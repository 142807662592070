import React, { Component } from "react";

class QTest2 extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value: "",
      value_l: "",
      value_r: "",
      can_do_lever: null,
      yesno: false
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);
    if (this.props.accessor) {
      let {
        value = "",
        value_l = "",
        value_r = "",
        can_do_lever = null,
        yesno = false
      } = this.props.accessor(this.props.answers);
      this.setState({ value, value_l, value_r, can_do_lever, yesno });
    } else {
      if (this.props.answers[this.props.index]) {
        let {
          value,
          value_l = "",
          value_r = "",
          can_do_lever = null,
          yesno = false
        } = this.props.answers[this.props.index];
        this.setState({ value, value_l, value_r, can_do_lever, yesno });
      }
    }
  }

  set_button = size => () => {
    this.setState({ can_do_lever: size, yesno: true });
    this.props.callback1(true);
  };
  set_value = e => {
    let value = e.target.value;
    // if (this.props.time) {
    //   value = value.replace("s", "");
    //   if (value.slice(-1) !== "s") value = value + "s";
    // }
    this.setState({ value });
  };
  set_value_uni = side => e => {
    let value = e.target.value;
    if (side === "left") {
      this.setState({ value_l: value });
    } else {
      this.setState({ value_r: value });
    }
  };

  keydown = event => {
    var KeyID = event.keyCode;
    switch (KeyID) {
      // case 8:
      //   let value = this.state.value.slice(0, -2) + "s";
      //   if (value === "s") value = "";
      //   this.setState({ value });
      // break;
      case 13:
        this.answer();
        break;
      default:
        break;
    }
  };

  answer = () => {
    if (this.props.unilateral && this.state.value_l && this.state.value_r) {
      this.props.callback2(this.state.value_l, this.state.value_r);
      this.props.handle_next();
    } else if (this.props.unilateral) {
      this._input2.focus();
    } else {
      this.props.callback2(this.state.value);
      this.props.handle_next();
    }
  };

  render() {
    return (
      <div>
        {this.props.title ? (
          <div className="label2 bold">{this.props.title.toUpperCase()}</div>
        ) : null}
        <div className="label">{this.props.text}</div>

        {!this.state.yesno ? (
          <div className="margin20">
            {this.props.label2.map(l => (
              <div className="label2">{l}</div>
            ))}

            <div>
              <button
                className={`button margin20 margin-w-10 ${
                  this.state.can_do_lever === "yes" ? "focused" : ""
                }`}
                onClick={this.set_button("yes")}
                style={{ width: 80 }}
              >
                YES
              </button>
              {!this.state.can_do_lever ? (
                <button
                  className={`button margin20 margin-w-10 `}
                  onClick={() => {
                    this.props.callback1(false);
                    this.props.handle_next();
                  }}
                  style={{ width: 80 }}
                >
                  NO
                </button>
              ) : null}
            </div>
          </div>
        ) : null}

        <div>
          {this.state.can_do_lever ? (
            <div className="margin20">
              {this.props.label3.map(l => (
                <div className="label2">{l}</div>
              ))}

              <div>
                {this.props.unilateral ? (
                  <div className="flex">
                    <div className="flex uni-input-wrapper">
                      <input
                        type={`${this.props.numeric ? "number" : "text"}`}
                        className="input"
                        name={this.props.name}
                        ref={inp => {
                          this._input = inp;
                        }}
                        value={this.state.value_l}
                        placeholder={this.props.placeholderl}
                        onChange={this.set_value_uni("left")}
                        onKeyDown={this.keydown}
                      />
                      {/*this.props.time && this.state.value_l.length > 0 ? (
                        <span className="time-s">s</span>
                      ) : (
                        ""
                      )*/}
                    </div>
                    <div className="flex uni-input-wrapper">
                      <input
                        type={`${this.props.numeric ? "number" : "text"}`}
                        className="input"
                        name={this.props.name}
                        ref={inp => {
                          this._input2 = inp;
                        }}
                        value={this.state.value_r}
                        placeholder={this.props.placeholderr}
                        onChange={this.set_value_uni("right")}
                        onKeyDown={this.keydown}
                        size={this.state.value_r.length}
                      />
                      {/*this.props.time && this.state.value_r.length > 0 ? (
                        <span className="time-s">s</span>
                      ) : (
                        ""
                      )*/}
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <input
                      type={`${this.props.numeric ? "number" : "text"}`}
                      className="input"
                      name={this.props.name}
                      ref={inp => {
                        this._input = inp;
                      }}
                      value={this.state.value}
                      placeholder={this.props.placeholder}
                      onChange={this.set_value}
                      onKeyDown={this.keydown}
                      size={this.state.value.length}
                    />
                    {/*this.props.time && this.state.value.length > 0 ? (
                      <span className="time-s">s</span>
                    ) : (
                      ""
                    )*/}
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>

        {this.state.value.length ||
        (this.state.value_l.length > 0 && this.state.value_r.length > 0) ? (
          <div className="button-wrap">
            <button className="button" onClick={this.answer}>
              NEXT
            </button>
          </div>
        ) : (
          <div className="button-wrap" />
        )}

        {false && this.props.skip && this.state.yesno ? (
          <div className="skip" onClick={this.props.handle_next}>
            {" "}
            SKIP{" "}
          </div>
        ) : null}
      </div>
    );
  }
}

export default QTest2;
