import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const width1 = 40;

class Grade extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value: "",
      grade1: "",
      grade2: "",
      grade3: "",
      ingym: false
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);
    let value = null;
    if (this.props.accessor) {
      value = this.props.accessor(this.props.answers).value || "";
    } else if (this.props.answers[this.props.index]) {
      value = this.props.answers[this.props.index].value || "";
    }
    let use_french = [2, "2"].includes(this.props.answers[6].value);
    if (value) {
      // console.log("loaded value is:", value);
      // let use_french = this.props.answers[6].value === 2;
      let grade1 = "",
        grade2 = "",
        grade3 = "";

      if (use_french && value && (value.includes("5.") || value.includes("v")))
        return;
      if (!use_french && !this.props.boulder && value.includes("v")) return;
      if (
        !this.props.boulder &&
        !use_french &&
        (value && !value.includes("5."))
      )
        return;
      if (this.props.boulder && !use_french && (value && value.includes("5.")))
        return;

      if (value.includes("v")) {
        grade1 = value.replace("v", "");
      } else {
        value = value.replace("5.", "");
        if (value.includes("+")) {
          value = value.replace("+", "");
          grade3 = "+";
        }
        if (value[0] === "1") {
          grade1 = value.slice(0, 2);
          grade2 = value.slice(2, 3);
        } else {
          grade1 = value.slice(0, 1);
          grade2 = value.slice(1, 2);
        }
      }
      // console.log("setting grades to", grade1, grade2, grade3);
      this.setState({ grade1, grade2, grade3 });
    }
  }

  onKeyPress = e => {
    if (e.keyCode === 13) {
      this.props.handle_next();
    }
  };

  answer = use_french => () => {
    let pre = "";

    if (
      (this.state.grade1 || this.state.grade2) &&
      !use_french &&
      !this.props.boulder
    )
      pre = "5.";

    if (this.props.boulder && !use_french && this.state.grade1) pre = "v";

    let grade = pre + this.state.grade1 + this.state.grade2 + this.state.grade3;

    this.props.callback(this.state.ingym ? "in_gym" : null, grade);
    this.props.handle_next();
  };

  select_grade = (num, grade) => () => {
    this.setState({ [num]: grade });
  };

  use_french = () => [2, "2"].includes(this.props.answers[6].value);

  show_next = () => {
    if (!this.props.boulder) {
      return this.state.grade1 && this.state.grade2;
    } else if (this.use_french()) {
      return this.state.grade1 && this.state.grade2;
    } else {
      return this.state.grade1;
    }
  };

  render() {
    // console.log("answers", this.props.answers);
    let use_french = this.use_french();
    // console.log("use_french", use_french);

    let numbers = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16"
    ];
    if (!this.props.boulder && !use_french)
      numbers = ["8", "9", "10", "11", "12", "13", "14", "15"];
    else if (use_french) numbers = ["5", "6", "7", "8", "9"];

    return (
      <div>
        {this.props.title ? (
          <div className="label2 bold">{this.props.title.toUpperCase()}</div>
        ) : null}
        <div className="label">{this.props.text}</div>

        <div>
          <div className="flex row">
            <div className="margin-t-20">
              {numbers.map(v => (
                <button
                  className={`button-grade ${
                    this.state.grade1 === v ? "focused" : ""
                  }`}
                  onClick={this.select_grade("grade1", v)}
                  style={{ width: width1 }}
                >
                  {v}
                </button>
              ))}
            </div>
          </div>

          {!this.props.boulder && !use_french ? (
            <div className="flex row margin-tb-20">
              {["a", "b", "c", "d"].map(v => (
                <button
                  className={`button-grade ${
                    this.state.grade2 === v ? "focused" : ""
                  }`}
                  onClick={this.select_grade("grade2", v)}
                  style={{ width: width1 }}
                >
                  {v}
                </button>
              ))}
            </div>
          ) : null}

          {use_french ? (
            <div className="flex row margin-tb-20">
              {["a", "b", "c"].map(v => (
                <button
                  className={`button-grade ${
                    this.state.grade2 === v ? "focused" : ""
                  }`}
                  onClick={this.select_grade("grade2", v)}
                  style={{ width: width1 }}
                >
                  {v}
                </button>
              ))}
              {use_french ? (
                <button
                  className={`flex row button-grade ${
                    this.state.grade3 === "+" ? "focused" : ""
                  }`}
                  onClick={this.select_grade("grade3", "+")}
                  style={{ width: width1 }}
                >
                  +
                </button>
              ) : null}
            </div>
          ) : null}

          <div className="label">
            {(this.state.grade1 || this.state.grade2) &&
            !use_french &&
            !this.props.boulder
              ? "5."
              : ""}
            {this.props.boulder && !use_french && this.state.grade1 ? "v" : ""}
            {this.state.grade1}
            {this.state.grade2}
            {this.state.grade3}
          </div>

          <div className="label2 ">
            <FontAwesomeIcon
              icon={this.state.ingym ? faCheckSquare : faSquare}
              className="checkbox"
              onClick={() => this.setState({ ingym: !this.state.ingym })}
            />
            Check if this was in a gym
          </div>
          {this.show_next() ? (
            <div className="button-wrap">
              <button className="button" onClick={this.answer(use_french)}>
                NEXT
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Grade;
