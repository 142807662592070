import React, { Component } from "react";

class QTest3 extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value: ""
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);
    if (this.props.accessor) {
      let {
        value = "",
        value_l = "",
        value_r = "",
        can_do_lever = null,
        yesno = false
      } = this.props.accessor(this.props.answers);
      this.setState({ value, value_l, value_r, can_do_lever, yesno });
    } else {
      if (this.props.answers[this.props.index]) {
        let {
          value,
          value_l = "",
          value_r = "",
          can_do_lever = null,
          yesno = false
        } = this.props.answers[this.props.index];
        this.setState({ value, value_l, value_r, can_do_lever, yesno });
      }
    }
  }

  set_value = e => {
    let value = e.target.value;
    // let value = e.target.value
    if (this.props.time) {
      value = value.replace("s", "");
      if (value.slice(-1) !== "s") value = value + "s";
    }

    this.setState({ value });
  };

  keydown = event => {
    var KeyID = event.keyCode;
    switch (KeyID) {
      case 8:
        // let value = this.state.value.slice(0, -2) + "s";
        // if (value === "s") value = "";
        // this.setState({ value });
        break;
      case 13:
        this.answer();
        break;
      default:
        break;
    }
  };

  answer = () => {
    this.props.callback(this.state.value);
    this.props.handle_next();
  };

  render() {
    return (
      <div>
        {this.props.title ? (
          <div className="label2 bold">{this.props.title.toUpperCase()}</div>
        ) : null}
        <div className="label">{this.props.text}</div>

        <div className="margin20">
          {this.props.label2.map(l => (
            <div className="label2">{l}</div>
          ))}
        </div>

        <div className={"flex margin40 height60"}>
          <input
            type={`${this.props.numeric ? "number" : "text"}`}
            className="input"
            name={this.props.name}
            ref={inp => {
              this._input = inp;
            }}
            value={this.state.value}
            placeholder={this.props.placeholder}
            onChange={this.set_value}
            onKeyDown={this.keydown}
          />
        </div>

        {this.state.value.length > 0 ? (
          <div className="button-wrap">
            <button className="button" onClick={this.answer}>
              NEXT
            </button>
          </div>
        ) : (
          <div className="button-wrap" />
        )}

        {false && this.props.skip ? (
          <div className="skip" onClick={this.props.handle_next}>
            {" "}
            SKIP{" "}
          </div>
        ) : null}
      </div>
    );
  }
}

export default QTest3;
