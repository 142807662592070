import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable_next: false
    };
  }
  render() {
    return (
      <div className="flex footer">
        <div className="completed-box">
          <span className="completed">
            {Math.round(
              ((this.props.index + 1) / (this.props.total + 1)) * 100
            )}
            % completed
          </span>
          <div className="bar-wrapper">
            <div
              className="bar"
              style={{
                width: ((this.props.index + 1) / (this.props.total + 1)) * 200
              }}
            />
          </div>
        </div>
        <div className="footer-buttons">
          <button
            className="up-down-button"
            onClick={this.props.go_back}
            disabled={this.props.index === 0}
          >
            <FontAwesomeIcon icon={faChevronUp} size="1x" />
          </button>
          <button
            className="up-down-button"
            disabled={!this.props.skip || this.state.disable_next}
            onClick={() => {
              this.props.forward();
              this.setState({ disable_next: true }, () =>
                setTimeout(() => this.setState({ disable_next: false }), 1000)
              );
            }}
          >
            <FontAwesomeIcon icon={faChevronDown} size="1x" />
          </button>
        </div>
      </div>
    );
  }
}

export default Footer;
