import React, { Component } from "react";
import { withRouter, useLocation } from "react-router-dom";

import qs from "qs";

import * as requests from "../helpers/requests";
import { api_url } from "../environment";

import "../index.css";
import "./style.css";

import Header from "../header";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

class TrainingSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = { q1: "", q2: "", q3: "", email: "", email_id: null };
    this.email_id = null;
  }

  componentDidMount() {
    let query = qs.parse(this.props.location.search.slice(1));
    this.email_id = query.id;
    this.setState({ email_id: this.email_id });
    console.log("email id is", this.email_id);

    // requests.post(url, JSON.stringify(body));
  }

  confirm = (e) => {
    // e.preventDefault();
    if ((this.state.q1 && this.state.q2, this.state.q3)) {
      e.preventDefault();
      let url = `${api_url}/training_survey_response/`;
      let body = {
        email_id: this.email_id,
        email: this.state.email,
        questions: [
          { key: "q1", answer: this.state.q1 },
          { key: "q2", answer: this.state.q2 },
          { key: "q3", answer: this.state.q3 },
        ],
      };
      requests
        .post(url, JSON.stringify(body))
        .catch((err) => console.log(err))
        .then(
          (res) => (window.location = "https://data.howtoclimb513.com/thanks")
        );
    }
    // e.preventDefault();
    // console.log(e);
    // window.location = "https://www.howtoclimb513.com";
  };

  keydown = () => {};

  render() {
    let row_count = 5;
    // let query = qs.parse(this.props.location.search.slice(1));
    // console.log(query.id);
    return (
      <div>
        <Header />

        <div className="flex">
          <div className="label margin20 t-align-c">
            Training Survey - Oct 2020
          </div>
          <form action="">
            <div className="main">
              {[
                {
                  title:
                    "What goals would you like to accomplish this coming climbing season?",
                  value: this.state.q1,
                  key: "q1",
                },
                {
                  title:
                    "What would you consider to be the biggest thing limiting your climbing? What keeps you from accomplishing your goals?",
                  value: this.state.q2,
                  key: "q2",
                },
                {
                  title:
                    "Where do you do most of your climbing training currently? What kind of equipment does that give you access to?",
                  value: this.state.q3,
                  key: "q3",
                },
              ].map((question) => {
                return (
                  <div className="width-100">
                    <div className="input-wrap t-align-l">
                      <p className="label2 t-align-l margin-b-0">
                        {question.title}
                      </p>
                      <textarea
                        rows={row_count}
                        type={"text"}
                        multiline
                        className="input-survey"
                        name={this.props.name}
                        required
                        maxlength={2048}
                        ref={(inp) => {
                          this._input = inp;
                        }}
                        value={question.value}
                        placeholder={""}
                        onChange={(e) =>
                          this.setState({ [question.key]: e.target.value })
                        }
                        onKeyDown={this.keydown}
                      />
                    </div>
                  </div>
                );
              })}

              {this.email_id ? null : (
                <div className="width-100">
                  <div className="input-wrap t-align-l">
                    <p className="label2 t-align-l margin-b-0">
                      What's your email?
                    </p>
                    <input
                      name={"email"}
                      type={"text"}
                      className="input-survey-email"
                      required
                      maxlength={256}
                      ref={(inp) => {
                        this._input = inp;
                      }}
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      onKeyDown={this.keydown}
                    />
                  </div>
                </div>
              )}

              <div className="noflex width-100">
                <div className="button-wrap margin20">
                  <button className="button" onClick={this.confirm}>
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(TrainingSurvey);
