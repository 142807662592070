import React, { Component } from "react";
import { withRouter, useLocation } from "react-router-dom";

import qs from "qs";

import * as requests from "../helpers/requests";
import { api_url } from "../environment";

import "../index.css";
import "./style.css";

import Header from "../header";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

class EmailUnsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = { reason: "" };
    this.email_id = null;
  }

  componentDidMount() {
    let query = qs.parse(this.props.location.search.slice(1));
    this.email = query.e;
    console.log("email id is", this.email);

    // requests.post(url, JSON.stringify(body));
  }

  confirm = (e) => {
    if (this.state.reason) {
      e.preventDefault();
      let url = `https://api.ascensionapp.com/link/unsubscribe/`;
      let body = {
        email: this.email,
        reason: this.state.reason,
        list: "513",
      };
      requests
        .post(url, JSON.stringify(body))
        .catch((err) => console.log(err))
        .then(
          (res) =>
            (window.location =
              "https://data.howtoclimb513.com/unsubscribe_success")
        );
    }
    // e.preventDefault();
    // console.log(e);
    // window.location = "https://www.howtoclimb513.com";
  };

  keydown = () => {};

  render() {
    let row_count = 5;
    // let query = qs.parse(this.props.location.search.slice(1));
    // console.log(query.id);
    return (
      <div>
        <Header />

        <div className="flex">
          <div className="label margin20 t-align-c">Unsubscribe</div>
          <form action="">
            <div className="main">
              {[
                {
                  title:
                    "Any feedback as to what we could have done differently?",
                  value: this.state.reason,
                  key: "reason",
                },
              ].map((question) => {
                return (
                  <div className="width-100">
                    <div className="input-wrap t-align-l">
                      <p className="label2 t-align-l margin-b-0">
                        {question.title}
                      </p>
                      <textarea
                        rows={row_count}
                        type={"text"}
                        multiline
                        className="input-survey"
                        name={this.props.name}
                        required
                        maxlength={2048}
                        ref={(inp) => {
                          this._input = inp;
                        }}
                        value={question.value}
                        placeholder={""}
                        onChange={(e) =>
                          this.setState({ [question.key]: e.target.value })
                        }
                        onKeyDown={this.keydown}
                      />
                    </div>
                  </div>
                );
              })}

              <div className="noflex width-100">
                <div className="button-wrap margin20">
                  <button className="button" onClick={this.confirm}>
                    UNSUBSCRIBE
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(EmailUnsubscribe);
