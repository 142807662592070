import React, { Component } from "react";

class GradingSystem extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value: "",
      show_next: false
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);
    // if (this.props.answers[this.props.index]) {
    let { value = "" } = this.props.accessor(this.props.answers);
    if (value) value = parseInt(value);
    this.setState({ value, show_next: true });
    // }
  }

  onKeyPress = e => {
    if (e.keyCode === 13) {
      this.props.handle_next();
    }
  };

  answer = value => () => {
    this.props.callback(value);
    this.props.handle_next();
  };

  render() {
    let choices = ["YOSEMITE (e.g. 5.13b)", "FRENCH (e.g. 8b)"];
    // console.log(
    //   "logged value",
    //   this.state.value,
    //   this.props.index,
    //   this.props.answers
    // );
    // if ([2, "2"].includes(this.props.answers[5].value))
    //   choices = ["V GRADE (e.g. v8)", "FRENCH (e.g. 7b)"];

    return (
      <div>
        {this.props.title ? (
          <div className="label2 bold">{this.props.title.toUpperCase()}</div>
        ) : null}
        <div className="label">{this.props.text}</div>
        {this.props.vert ? (
          <div className="margin20">
            <div>
              <button
                className={`button ${this.state.value === 1 ? "focused" : ""}`}
                onClick={this.answer(1)}
                style={{ width: this.props.button_width }}
              >
                {choices[0]}
              </button>
            </div>
            <div className="or_text">or</div>
            <div>
              <button
                className={`button ${this.state.value === 2 ? "focused" : ""}`}
                onClick={this.answer(2)}
                style={{ width: this.props.button_width }}
              >
                {choices[1]}
              </button>
            </div>
          </div>
        ) : (
          <div className="margin20">
            <div>
              <button
                className={`button margin20 ${
                  this.state.value === 1 ? "focused" : ""
                }`}
                onClick={this.answer(1)}
                style={{ width: this.props.button_width }}
              >
                {choices[0]}
              </button>

              <button
                className={`btton margin20 ${
                  this.state.value === 2 ? "focused" : ""
                }`}
                onClick={this.answer(2)}
                style={{ width: this.props.button_width }}
              >
                {choices[1]}
              </button>
            </div>
          </div>
        )}

        {this.state.show_next ? (
          <div className="button-wrap">
            <button className="button" onClick={this.props.handle_next}>
              NEXT
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default GradingSystem;
