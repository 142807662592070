import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class BarOneArmHang extends Component {
  chartRef = React.createRef();

  render() {
    let r = this.props.results;
    let selection = this.props.selection;
    let comparison = r.your_comparison[selection];
    let next_grade = r.next_grade[selection];

    let datasets = [
      {
        label: "Your Results",
        barThickness: 15,
        data: [
          r.you.hang_1_arm_15,
          r.you.hang_1_arm_21,
          r.you.hang_1_arm_33,
          r.you.hang_1_arm_50
        ],
        pointStyle: "rectRounded",
        pointRadius: [0, 10, 0, 10, 0],
        fill: false,
        backgroundColor: "#ff0000",
        borderColor: "#ff0000"
      },
      {
        label: `${comparison.label} Average`,
        barThickness: 15,
        data: [
          comparison.hang_1_arm_15,
          comparison.hang_1_arm_21,
          comparison.hang_1_arm_33,
          comparison.hang_1_arm_50
        ],
        pointStyle: "rectRounded",
        pointRadius: [0, 10, 0, 10, 0],
        fill: false,
        backgroundColor: "#bbb",
        borderColor: "#bbb"
      },
      {
        label: `${next_grade.label} Average`,
        barThickness: 15,
        data: [
          next_grade.hang_1_arm_15,
          next_grade.hang_1_arm_21,
          next_grade.hang_1_arm_33,
          next_grade.hang_1_arm_50
        ],
        pointStyle: "rectRounded",
        pointRadius: [0, 10, 0, 10, 0],
        fill: false,
        backgroundColor: "#243e4c",
        borderColor: "#243e4c"
      }
    ];
    //pull out the redundant dataset when the grade compare is the same for next_grade
    if (comparison.label === next_grade.label) datasets.splice(2);

    return (
      <Bar
        data={{
          labels: [`15mm`, `21mm`, "33mm", "55mm+"],
          datasets
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                categorySpacing: 1.0
              }
            ]
          },
          legend: {
            position: "bottom",
            display: true,
            labels: {
              fontColor: "rgb(0,0,0)"
            },
            labels: {
              boxWidth: 20
            },
            align: "start"
          },
          title: {
            display: false,
            text: "test"
          }
        }}
      />
    );
  }
}

export default BarOneArmHang;
