import React, { Component } from "react";
import "whatwg-fetch";

import { withRouter } from "react-router-dom";

import * as requests from "./helpers/requests";
import { api_url } from "./environment";

import QWrapper from "./qwrapper";
import QText from "./questions/text";
import QChoice2 from "./questions/choice2";
import QChoice3 from "./questions/choice3";
import QTest1 from "./questions/test1";
import QTest2 from "./questions/test2";
import QTest3 from "./questions/test3";
import QCardio from "./questions/cardio";
import QWeight from "./questions/weight";
import GradingSystem from "./questions/grading_system";
import Grade from "./questions/grade";
import Email from "./questions/email";

import Header from "./header";

import "./index.css";

const question_order = [
  0,
  1,
  2,
  3,
  4,
  6,
  7,
  8,
  19,
  20,
  5,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18
];

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showing_index: null,
      show_footer: true
    };
    this.survey = {};
    this.answers = {};
  }

  componentDidMount() {
    let is_mobile =
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1;

    this.setState({ showing_index: 0, is_mobile });

    this.combined_size = window.innerHeight + window.innerWidth;

    window.addEventListener("resize", this.windowResized);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResized);
  }

  windowResized = () => {
    if (
      this.state.is_mobile &&
      this.combined_size !== window.innerHeight + window.innerWidth &&
      Math.abs(this.combined_size - (window.innerHeight + window.innerWidth)) >
        200
    )
      this.show_footer(false);
    else {
      setTimeout(() => this.show_footer(true), 100);
    }
  };

  remount = () => {
    setTimeout(
      () =>
        this.setState({
          showing_index: this.state.showing_index - 1
        }),
      50
    );
  };

  unmount = () => {
    if (this.state.showing_index + 1 === Object.keys(this.questions).length) {
      this.props.history.push("/yourreport");
    } else
      setTimeout(
        () =>
          this.setState({
            showing_index: this.state.showing_index + 1
          }),
        50
      );
  };

  patch_survey = field => value => {
    // console.log("survey coming into patch survey", this.survey);
    let url = `${api_url}/survey/${this.survey.id}/`;
    if (field === "age") value = Math.floor(value);
    let body = { ...this.survey, [field]: value };
    // console.log(body);
    requests.patch(url, JSON.stringify(body)).then(res => (this.survey = res));
    this.answers[this.state.showing_index] = { value1: value };
  };

  answer_question = (id, field, meta) => value => {
    // console.log("answer question", id, field, value);
    this.answers[id] = { value };
    let url = `${api_url}/answer/`;
    if (field === "intvalue") value = Math.floor(value);
    if (field === "decvalue") value = parseFloat(value).toFixed(2);
    let body = {
      survey: this.survey.id,
      type: id,
      [field]: value
    };
    requests.post(url, JSON.stringify(body)).catch(err => console.log(err));
  };

  answer_question_unilateral = (id, field, field_unilateral) => (
    value,
    value_unilateral
  ) => {
    // console.log("answer question", id, field, value);
    this.answers[id] = value;
    let url = `${api_url}/answer/`;
    if (field === "intvalue") value = Math.floor(value);
    if (field === "intvalue_unilateral")
      value_unilateral = Math.floor(value_unilateral);
    if (field === "decvalue") value = parseFloat(value).toFixed(2);
    if (field === "decvalue_unilateral")
      value = parseFloat(value_unilateral).toFixed(2);

    let body = {
      survey: this.survey.id,
      type: id,
      [field]: value,
      [field_unilateral]: value_unilateral
    };
    requests.post(url, JSON.stringify(body)).catch(err => console.log(err));
  };

  answer_question_meta = (id, field) => (meta, value) => {
    // console.log("answer question meta", id, field, value, this.survey.id, meta);
    this.answers[id] = { value, field, meta };
    let url = `${api_url}/answer/`;
    if (field === "intvalue") value = Math.floor(value);
    if (field === "decvalue") value = parseFloat(value).toFixed(2);

    let body = {
      survey: this.survey.id,
      type: id,
      [field]: value,
      meta
    };
    requests.post(url, JSON.stringify(body)).catch(err => console.log(err));
  };

  //we track value here as value field for left and value_unilateral for right
  answer_question_unilateral_meta = (id, field, field_unilateral) => (
    meta,
    value,
    value_unilateral
  ) => {
    // console.log("answer question", id, field, value, this.survey.id, meta);
    this.answers[id] = { value, value_unilateral, field, meta };
    let url = `${api_url}/answer/`;
    if (field === "intvalue") value = Math.floor(value);
    if (field === "intvalue_unilateral")
      value_unilateral = Math.floor(value_unilateral);
    if (field === "decvalue") value = parseFloat(value).toFixed(2);
    if (field === "decvalue_unilateral")
      value = parseFloat(value_unilateral).toFixed(2);

    let body = {
      survey: this.survey.id,
      type: id,
      [field]: value,
      [field_unilateral]: value_unilateral,
      meta
    };
    requests.post(url, JSON.stringify(body)).catch(err => console.log(err));
  };

  show_footer = show_footer => this.setState({ show_footer });

  questions = {
    0: (
      <QText
        index={0}
        text={"What's your name?"}
        placeholder={"Enter your first name"}
        placeholder2={"Enter your last name"}
        name="name"
        callback={(first_name, last_name) => {
          this.survey = { ...this.survey, first_name, last_name };
          this.answers[0] = { value1: first_name, value2: last_name };
        }}
        validator={v => v.length > 0}
      />
    ),
    1: (
      <Email
        index={1}
        text={"What's your email?"}
        placeholder={"Enter your email"}
        name="email"
        callback={(email, survey, handle_next) => {
          // console.log("survey that came back is", survey);
          this.survey = survey;
          this.props.set_nav_email(email);
          this.answers[1] = { value1: email };
          handle_next();
        }}
        validator={value =>
          value &&
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
            ? true
            : false
        }
        email
        set_answers={answers => {
          // console.log("answers that came back", answers);
          this.answers = answers;
        }}
      />
    ),
    2: (
      <QText
        index={2}
        text={"How old are you?"}
        placeholder={"Enter your age"}
        name="age"
        callback={this.patch_survey("age")}
        numeric
        validator={v => v > 0}
        survey_key={"age"}
      />
    ),
    3: (
      <QWeight
        index={3}
        text={"How much do you weigh?"}
        placeholder={"Enter your weight"}
        name="weight"
        callback={this.patch_survey("weight")}
        skip
        numeric
        survey_key={"weight"}
      />
    ),
    4: (
      <QChoice3
        index={4}
        text={"What's your Gender?"}
        name="name"
        choices={["MALE", "FEMALE", "UNSPECIFIED"]}
        callback={this.patch_survey("gender")}
        vert
        button_width={190}
        survey_key={"gender"}
      />
    ),
    5: (
      <QChoice2
        index={5}
        text={"Primarily do you"}
        label={[]}
        name="name"
        choices={["SPORT CLIMB", "BOULDER"]}
        possible_answers={[1, 2]}
        callback={this.answer_question(5, "intvalue")}
        vert
        button_width={190}
        accessor={answers => {
          if (answers[5]) return answers[5];
          else return {};
        }}
      />
    ),
    6: (
      <GradingSystem
        index={6}
        text={"What grading system do you use primarily?"}
        name="name"
        callback={this.answer_question(6, "intvalue")}
        vert
        button_width={160}
        accessor={answers => {
          if (answers[6]) return answers[6];
          else return {};
        }}
      />
    ),
    7: (
      <Grade
        index={7}
        text={"Hardest sport climb onsight (last 2 years)?"}
        name="name"
        callback={this.answer_question_meta(7, "charvalue")}
        vert
        button_width={190}
        accessor={answers => {
          if (answers[7]) return answers[7];
          else return {};
        }}
      />
    ),
    8: (
      <Grade
        index={8}
        text={"Hardest sport climb redpoint (last 2 years)?"}
        name="name"
        callback={this.answer_question_meta(8, "charvalue")}
        vert
        button_width={190}
        accessor={answers => {
          if (answers[8]) return answers[8];
          else return {};
        }}
      />
    ),
    9: (
      <QTest1
        index={9}
        title={"Pillar 1: Finger Strength"}
        text={"Two Arm Edge Hang"}
        placeholder={"Enter Time (seconds)"}
        label2={["Choose one or more edge sizes", "Hang as long as you can"]}
        twoarm
        skip
        callback2={this.answer_question_meta(9, "charvalue")}
        numeric
        accessor={answers => {
          if (answers[9]) {
            let answer = answers[9];
            let ret = {
              edge_selected: JSON.parse(answer.meta),
              edges: JSON.parse(answer.value)
            };
            return ret;
          } else return {};
        }}
      />
    ),
    10: (
      <QTest2
        index={10}
        title={"Pillar 2: Body Tension and Core Strength"}
        text={"Front Lever"}
        placeholder={"Enter Time (seconds)"}
        label2={["Can you do a front lever?"]}
        label3={["For how long?"]}
        time
        skip
        callback1={this.answer_question(10, "boolvalue")}
        callback2={this.answer_question(11, "decvalue")}
        numeric
        accessor={answers => {
          if (answers[10] && answers[11]) {
            let can_do_lever = answers[10].value;
            let value = answers[11].value;
            let yesno = !!answers[11].value;
            return { can_do_lever, value, yesno };
          } else return {};
        }}
      />
    ),
    11: (
      <QTest3
        index={12}
        title={"Pillar 2: Body Tension and Core Strength"}
        text={"Hanging Knee Ups"}
        placeholder={"Enter Number"}
        label2={[
          "Bring knees to chest and lower. Knees should touch the arm between armpit and elbow.",
          "Repeat continuously until failure",
          "Record max reps",
          ""
        ]}
        skip
        callback={this.answer_question(12, "intvalue")}
        numeric
        accessor={answers => {
          if (answers[12]) return answers[12];
          else return {};
        }}
      />
    ),
    12: (
      <QTest1
        index={13}
        title={"Pillar 3: Shoulder Stability"}
        text={"One Arm Edge Hang"}
        placeholderl={"Left Arm (seconds)"}
        placeholderr={"Right Arm (seconds)"}
        yesno
        unilateral
        yesnoq={"Can you one-arm hang off a flat edge?"}
        label2={["Hang one-arm off an edge", "As long as you can"]}
        skip
        time
        numeric
        callback1={this.answer_question(13, "boolvalue")}
        callback2={this.answer_question_meta(14, "charvalue")}
        accessor={answers => {
          if (answers[14]) {
            let answer = answers[14];
            let ret = {
              edge_selected: JSON.parse(answer.meta),
              edges: JSON.parse(answer.value)
            };
            return ret;
          } else return {};
        }}
      />
    ),
    13: (
      <QTest3
        index={15}
        title={"Pillar 3: Shoulder Stability"}
        text={"Push Ups"}
        placeholder={"Enter Number"}
        label2={[
          "Thumbs in-line with nipples, at bottom, elbows should break 90. Straighten arms fully at the top.",
          "Record maximum number of push ups"
        ]}
        skip
        callback={this.answer_question(15, "intvalue")}
        numeric
        accessor={answers => {
          if (answers[15]) return answers[15];
          else return {};
        }}
      />
    ),

    14: (
      <QTest2
        title={"Pillar 4: Pulling Strength"}
        text={"Pull Ups"}
        placeholder={"Enter Number"}
        label2={["Can you do a pull up?"]}
        label3={[
          "Chin must go over hands. Palms forward. Arms straighten fully at the bottom.",
          "Record maximum number of pull ups"
        ]}
        callback1={this.answer_question(16, "boolvalue")}
        callback2={this.answer_question(17, "intvalue")}
        skip
        numeric
        accessor={answers => {
          if (answers[16] && answers[17]) {
            let can_do_lever = answers[16].value;
            let value = answers[17].value;
            let yesno = !!answers[17].value;
            return { can_do_lever, value, yesno };
          } else return {};
        }}
      />
    ),
    15: (
      <QTest2
        title={"Pillar 5: Single leg power & stability"}
        text={"Single Leg Squat"}
        unilateral
        placeholderl={"Left Leg"}
        placeholderr={"Right Leg"}
        label2={["Can you do a full depth pistol squat?"]}
        label3={["Record maximum number per leg"]}
        skip
        numeric
        callback1={this.answer_question(18, "boolvalue")}
        callback2={this.answer_question_unilateral(
          19,
          "intvalue",
          "intvalue_unilateral"
        )}
        accessor={answers => {
          if (answers[18] && answers[19]) {
            let can_do_lever = answers[18].value;
            let value_l = answers[19].value;
            let value_r = answers[19].value2;
            let yesno = !!answers[19].value;
            return { can_do_lever, value_l, value_r, yesno };
          } else return {};
        }}
      />
    ),
    16: (
      <QChoice2
        title={"Pillar 6: Flexibility"}
        text={"Can you touch your toes while standing?"}
        label={["without bending your knees"]}
        name="name"
        choices={["YES", "NO"]}
        possible_answers={[true, false]}
        callback={this.answer_question(20, "boolvalue")}
        button_width={80}
        accessor={answers => {
          if (answers[20]) return answers[20];
          else return {};
        }}
      />
    ),
    17: (
      <QChoice2
        title={"Pillar 6: Flexibility"}
        text={"Can you touch your toes while sitting?"}
        label={["without bending your knees"]}
        name="name"
        choices={["YES", "NO"]}
        possible_answers={[true, false]}
        callback={this.answer_question(21, "boolvalue")}
        button_width={80}
        accessor={answers => {
          if (answers[21]) return answers[21];
          else return {};
        }}
      />
    ),
    18: (
      <QCardio
        title={"Pillar 7: Cardio Fitness"}
        text={"Do you do any cardio training?"}
        placeholder={"Enter Time (seconds)"}
        label2={[]}
        label3={["How many total hours a week?"]}
        label4={["How many times per week?"]}
        callback1={this.answer_question(22, "boolvalue")}
        callback2={this.answer_question(23, "intvalue")}
        callback3={this.answer_question(24, "intvalue")}
        complete={c => {
          this.patch_survey("complete")(c);
        }}
        set_survey={() => this.props.set_survey(this.survey)}
        accessor={answers => {
          let yesno = false;
          if (answers[22]) {
            let can_do_lever = answers[22].value;
            if (can_do_lever) {
              if (answers[23] || answers[24]) {
                yesno = true;
              }
              if (answers[23] && answers[24]) {
                let hours = answers[23].value;
                if (hours) hours = parseInt(hours);
                let frequency = answers[24].value;
                if (frequency) frequency = parseInt(frequency);
                return { can_do_lever, hours, frequency, yesno };
              }
            } else return { can_do_lever, yesno };
          } else return {};
        }}
      />
    ),
    19: (
      <Grade
        index={19}
        text={"Hardest boulder onsight (last 2 years)?"}
        name="name"
        callback={this.answer_question_meta(25, "charvalue")}
        vert
        button_width={190}
        boulder
        accessor={answers => {
          if (answers[25]) return answers[25];
          else return {};
        }}
      />
    ),
    20: (
      <Grade
        index={20}
        text={"Hardest boulder redpoint (last 2 years)?"}
        name="name"
        callback={this.answer_question_meta(26, "charvalue")}
        vert
        button_width={190}
        boulder
        accessor={answers => {
          if (answers[26]) return answers[26];
          else return {};
        }}
      />
    )
  };

  render() {
    // console.log("survey is", this.survey);
    // console.log("answers", this.answers, this.answers[1]);

    return (
      <div className="main-test">
        <Header fixed />
        <div className="box">
          {question_order.map((q_order, index) => {
            return this.state.showing_index === index ? (
              <QWrapper
                key={index}
                index={index}
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                fullName={this.state.fullName}
                unmount={this.unmount}
                remount={this.remount}
                child={this.questions[q_order]}
                ref={ref => (this._q = ref)}
                questions={this.questions}
                question_order={question_order}
                answers={this.answers}
                value1={this.answers[index] && this.answers[index].value1}
                value2={this.answers[index] && this.answers[index].value2}
                show_footer={this.state.show_footer}
                survey={this.survey}
              />
            ) : null;
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(Test);
