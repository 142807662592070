import React, { Component } from "react";
import * as requests from "../helpers/requests";
import { api_url } from "../environment";

class Email extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value1: "",
      value2: "",
      focused: false,
      focused2: false,
      state: "email",
      code: "",
      message: ""
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);
    if (this.props.answers[this.props.index]) {
      let { value1, value2 = "" } = this.props.answers[this.props.index];
      this.setState({ value1, value2 });
    }
  }
  get_create_survey = (state, email) => {
    let url = `${api_url}/survey/`;
    let body = {
      first_name: this.props.survey.first_name,
      last_name: this.props.survey.last_name,
      email,
      state,
      survey_id: (this.survey && this.survey.id) || null
    };
    return requests.post(url, JSON.stringify(body));
  };

  handle_email = state => async () => {
    //we first send in the survey with the email to check if a valid survey in the applicable
    //timeframe exists, or if we can just create a new one straight up
    let res = await this.get_create_survey(state, this.state.value1);
    // console.log("handle email res", res);
    if (res.status === "verify") {
      this.setState({ state: "verify", require_code: res.require_code });
    } else if (state === "update" || state === "start_new") {
      if (this.state.require_code)
        this.setState({ state: "code", decision: state });
      else if (state === "update") {
        //need to go back into the server and grab the survey results
        let url = `${api_url}/survey_answers/${res.data.id}/`;
        let res2 = await requests.get(url);
        if (res2.answers) this.props.set_answers(JSON.parse(res2.answers));
        this.props.callback(
          this.state.value1,
          res.data,
          this.props.handle_next
        );
      } else {
        // this.props.callback(this.state.value1, res.data);
        this.props.callback(
          this.state.value1,
          res.data,
          this.props.handle_next
        );
        // this.props.handle_next();
      }
    } else {
      this.props.callback(this.state.value1, res.data, this.props.handle_next);
      // this.props.handle_next();
    }
  };

  handle_code = async () => {
    let url = `${api_url}/survey/`;
    let body = {
      email: this.state.value1,
      state: "code",
      code: this.state.code,
      decision: this.state.decision
    };
    let res = await requests.post(url, JSON.stringify(body));
    if (res.data) {
      // console.log("survey from start_new decision", res.data);
      this.props.callback(this.state.value1, res.data, this.props.handle_next);
      if (this.state.decision === "update") {
        //need to go back into the server and grab the survey results
        url = `${api_url}/survey_answers/${res.data.id}/`;
        res = await requests.get(url);

        if (res.answers) this.props.set_answers(JSON.parse(res.answers));
      } else {
        this.props.set_answers({});
      }
      this.props.handle_next();
    } else if (res.state === "invalid_code")
      this.setState({
        message:
          "That code is wrong or no longer valid. Please check your email and try again.",
        code: ""
      });
  };

  onKeyPress = e => {
    if (e.keyCode === 13) {
      if (
        this.state.state === "email" &&
        this.props.validator(this.state.value1)
      )
        this.handle_email("email")();
      else if (this.state.state === "code") this.handle_code();
      else if (this.code_validator(this.state.code)) this.props.handle_next();
    }
  };

  code_validator = () => {
    return this.state.code.length === 4;
  };

  render() {
    // console.log("survey", this.props.survey);
    if (this.state.state === "email") {
      return (
        <div>
          {this.props.title ? (
            <div className="label2 bold">{this.props.title.toUpperCase()}</div>
          ) : null}
          <div className="label">{this.props.text}</div>
          <div className="flex">
            <input
              type={`${this.props.numeric ? "number" : "text"}`}
              className={`input input-100 ${
                this.props.email ? "lowercase" : ""
              }`}
              name={this.props.name}
              ref={inp => {
                this._input = inp;
              }}
              value={this.state.value1}
              placeholder={this.props.placeholder}
              onKeyDown={this.onKeyPress}
              onChange={e => this.setState({ value1: e.target.value.trim() })}
              onFocus={() => {
                this.setState({ focused: true });
              }}
              onBlur={() =>
                setTimeout(() => this.setState({ focused: false }), 100)
              }
            />
          </div>
          {(this.props.placeholder2 && this.state.value1.length > 0) ||
          this.props.value2 ? (
            <div className="flex">
              <input
                type={`${this.props.numeric ? "number" : "text"}`}
                className="input input-100"
                name={this.props.name}
                ref={inp => {
                  this._input2 = inp;
                }}
                value={this.state.value2}
                placeholder={this.props.placeholder2}
                onKeyDown={this.onKeyPress}
                onChange={e => this.setState({ value2: e.target.value.trim() })}
                onFocus={() => this.setState({ focused2: true })}
                onBlur={() =>
                  setTimeout(() => this.setState({ focused2: false }), 100)
                }
              />
            </div>
          ) : null}

          {(this.state.value1.length > 0 &&
            !this.props.placeholder2 &&
            this.props.validator(this.state.value1)) ||
          (this.state.value1.length > 0 && this.state.value2.length > 0) ? (
            <div className="button-wrap">
              <button className="button" onClick={this.handle_email("email")}>
                NEXT
              </button>
            </div>
          ) : (
            <div className="button-wrap" />
          )}
        </div>
      );
    } else if (this.state.state === "verify") {
      return (
        <div>
          <div className="margin-t-20">
            <div className="label2">
              You've previously taken the test with this email, or you didn't
              previously complete it.
            </div>
            <div className="label2">Would you like to:</div>
          </div>

          <div className="margin20 ">
            <div>
              <button
                className="button"
                onClick={this.handle_email("update")}
                style={{ width: 250 }}
              >
                UPDATE TEST
              </button>
            </div>
            <div className="or_text">or</div>
            <div>
              <button
                className="button"
                onClick={this.handle_email("start_new")}
                style={{ width: 250 }}
              >
                RETAKE TEST
              </button>
            </div>
          </div>
        </div>
      );
    } else if (this.state.state === "code") {
      return (
        <div>
          <div className="margin-t-20">
            <div className="label2">
              We've emailed you a short verification code to make sure it's you.
            </div>
            <div className="label2">Please enter it here:</div>
          </div>

          <div className="flex">
            <input
              type={`number`}
              className="input input-100"
              name={this.props.name}
              ref={inp => {
                this._input2 = inp;
              }}
              value={this.state.code}
              placeholder={"verification code"}
              onKeyDown={this.onKeyPress}
              onChange={e =>
                this.setState({ code: e.target.value.trim(), message: "" })
              }
              onFocus={() => this.setState({ focused2: true })}
              onBlur={() =>
                setTimeout(() => this.setState({ focused2: false }), 100)
              }
            />
            {this.code_validator(this.state.code) ? (
              <div className="button-wrap">
                <button className="button" onClick={this.handle_code}>
                  NEXT
                </button>
              </div>
            ) : (
              <div className="button-wrap" />
            )}
            <span>{this.state.message}</span>
          </div>
        </div>
      );
    }
  }
}

export default Email;
