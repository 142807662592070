import React, { Component } from "react";
import Footer from "./questions/footer";
import "./index.css";

//import QuestionData from "./QuestionData";

class QWapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => this.setState({ show: true }), 50);
  }

  componentWillUnmount() {
    // console.log("unmount of Q", this.props.index);
  }

  handle_next = () => {
    setTimeout(() => this.setState({ hide: true }), 100);
    setTimeout(this.props.unmount, 500);
  };

  handle_back = () => {
    setTimeout(() => this.setState({ show: false }), 100);
    setTimeout(this.props.remount, 500);
  };

  render() {
    return (
      <div className="flex">
        <div
          className={`ex ${this.state.show ? "show" : ""} ${
            this.state.hide ? "hide" : ""
          }`}
        >
          {React.cloneElement(this.props.child, {
            ...this.props,
            handle_next: this.handle_next
          })}
        </div>
        {this.props.show_footer ? (
          <Footer
            index={this.props.index}
            total={Object.keys(this.props.questions).length}
            go_back={this.handle_back}
            skip={
              (this.props.child.props.skip ||
                this.props.answers[this.props.index]) &&
              this.props.index !== 20
            }
            forward={this.handle_next}
          />
        ) : null}
      </div>
    );
  }
}

export default QWapper;
