import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import "./index.css";

import Header from "./header";

class Rules extends Component {
  render() {
    return (
      <div>
        <Header red />

        <div className="box-red width-100">
          <div className="inner-box">
            <div className="padding-b-20">
              <div className="white title">The Rules</div>
            </div>
            <div className="t-align-l white">
              <ul>
                <li>
                  <strong>Honest and accurate reporting</strong> - the ability
                  to correlate your data is a good as you make it.
                </li>
                <li>
                  <strong>No resting</strong> - all reps must be continuous with
                  nothing more than a pause at the top and bottom of the rep.
                </li>
                <li>
                  <strong>High Quality</strong> - have standards. Only count the
                  work you think is worth counting.
                </li>
              </ul>
            </div>

            <div className="margin20">
              <button
                className="button border-white"
                onClick={() => this.props.history.push("/consent")}
              >
                <span>OKAY</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Rules);
