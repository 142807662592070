// import { getToken, clearToken } from "./utility";
// import { all, takeLatest, put, call, select } from "redux-saga/effects";

const defaultHeaders = (headers = {}, userHeaders = {}) => ({
  ...headers,
  ...userHeaders
});

const handleError = async response => {
  if (response.ok) {
    // console.log("resonse seemed okay");
    return response;
  }
  if (response.status >= 400 && response.status <= 403) {
    console.log("reponse failed", response);
    console.log(JSON.stringify(response));
    console.log(response.statusText);
    let test = await response.json();
    console.log(test);

    // clearToken();
    window.location.assign("/login");
  } else if (response.status === 409) {
    return response;
  } else {
    console.log(response);
    console.log(JSON.stringify(response));

    throw Error(response.statusText);
  }
};

export const get = (url, headers = {}) => {
  const options = {
    method: "GET",
    headers: defaultHeaders(headers)
  };
  return fetch(url, options)
    .then(handleError)
    .then(res => res.json());
};

export const _delete = (url, headers = {}) => {
  const options = {
    method: "DELETE",
    headers: defaultHeaders(headers)
  };
  return fetch(url, options).then(handleError);
};

export const post = (url, body, headers = {}) => {
  const options = {
    method: "POST",
    headers: defaultHeaders(
      {
        "Content-Type": "application/json"
      },
      headers
    ),
    body
  };
  return fetch(url, options)
    .then(handleError)
    .then(res => res.json())
    .catch(err => alert(err));
};

export const post_raw = (url, body, headers = {}) => {
  const options = {
    method: "POST",
    headers: defaultHeaders(
      {
        "Content-Type": "application/json"
      },
      headers
    ),
    body
  };
  return fetch(url, options).then(handleError);
};

export const patch = (url, body, headers = {}) => {
  const options = {
    method: "PATCH",
    headers: defaultHeaders(
      {
        "Content-Type": "application/json"
      },
      headers
    ),
    body
  };
  return fetch(url, options)
    .then(handleError)
    .then(res => res.json())
    .catch(err => {
      throw Error(err);
    });
};
