import React, { Component } from "react";

class QText extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value: "",
      unit: "lbs"
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);
    if (
      this.props.survey_key &&
      this.props.survey[this.props.survey_key] !== null
    ) {
      this.setState({ value: this.props.survey[this.props.survey_key] });
    } else if (this.props.answers[this.props.index]) {
      let { value1 } = this.props.answers[this.props.index];
      this.setState({ value: value1 });
    }
  }

  next_screen = () => {
    let value = parseFloat(this.state.value);
    if (this.state.unit === "kg") value = value * 2.20462;

    this.props.callback(value.toFixed(2));
    this.props.handle_next();
  };

  onKeyPress = e => {
    if (e.keyCode === 13) {
      this.next_screen();
    }
  };

  select_unit = size => () => {
    this.setState({ unit: size });
  };

  render() {
    return (
      <div>
        <div className="label">{this.props.text}</div>
        <div className="flex">
          <input
            type={`${this.props.numeric ? "number" : "text"}`}
            className="input"
            name={this.props.name}
            ref={inp => {
              this._input = inp;
            }}
            placeholder={this.props.placeholder}
            onKeyDown={this.onKeyPress}
            onChange={e => this.setState({ value: e.target.value })}
            value={this.state.value}
          />
        </div>
        <div className="margin20">
          <button
            className={`button button-line ${
              this.state.unit === "lbs" ? "focused" : "gray"
            }`}
            onClick={this.select_unit("lbs")}
            style={{ width: 80 }}
          >
            <span>lbs</span>
          </button>
          <button
            className={`button button-line ${
              this.state.unit === "kg" ? "focused" : "gray"
            }`}
            onClick={this.select_unit("kg")}
            style={{ width: 80 }}
          >
            <span>kg</span>
          </button>
        </div>

        {this.state.value.length > 0 ? (
          <div className="button-wrap">
            <button className="button" onClick={this.next_screen}>
              NEXT
            </button>
          </div>
        ) : (
          <div className="button-wrap" />
        )}
        {false && this.props.skip ? (
          <div className="skip" onClick={this.props.handle_next}>
            {" "}
            SKIP{" "}
          </div>
        ) : null}
      </div>
    );
  }
}

export default QText;
