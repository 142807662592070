import React, { Component } from "react";

class QTest2 extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value: "",
      can_do_lever: null,
      hours: null,
      frequency: null,
      show_next: false
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);

    if (this.props.accessor) {
      let {
        value = "",
        can_do_lever = null,
        hours = null,
        frequency = null,
        yesno = false
      } = this.props.accessor(this.props.answers);
      this.setState({
        value,
        can_do_lever,
        hours,
        frequency,
        yesno,
        show_next: can_do_lever !== null
      });
    }
  }

  set_button = yesno => () => {
    this.setState({ yesno });
    this.props.callback1(true);
  };

  select_hours = hours => () => this.setState({ hours });
  select_frequency = frequency => () => this.setState({ frequency });

  keydown = event => {
    var KeyID = event.keyCode;
    switch (KeyID) {
      // case 8:
      //   let value = this.state.value.slice(0, -2) + "s";
      //   if (value === "s") value = "";
      //   this.setState({ value });
      //   break;
      case 13:
        this.props.set_survey();
        this.props.handle_next();
        break;
      default:
        break;
    }
  };

  answer = () => {
    this.props.callback2(this.state.hours);
    this.props.callback3(this.state.frequency);
    this.props.set_survey();
    this.props.complete(true);
    this.props.handle_next();
  };

  render() {
    // console.log("cardio", this.state);
    return (
      <div>
        {this.props.title ? (
          <div className="label2 bold">{this.props.title.toUpperCase()}</div>
        ) : null}
        <div className="label">{this.props.text}</div>

        <div className="margin-t-20">
          {this.props.label2.map(l => (
            <div className="label2">{l}</div>
          ))}
        </div>

        {!this.state.yesno ? (
          <div>
            <button
              className={`button margin20 margin-w-10 ${
                this.state.can_do_lever === true ? "focused" : ""
              }`}
              onClick={this.set_button(true)}
              style={{ width: 80 }}
            >
              YES
            </button>
            {!this.state.yesno ? (
              <button
                className={`button margin20 margin-w-10 ${
                  this.state.can_do_lever === false ? "focused" : ""
                }`}
                onClick={() => {
                  this.props.callback1(false);
                  this.props.complete(true);
                  this.props.set_survey();
                  this.props.handle_next();
                }}
                style={{ width: 80 }}
              >
                NO
              </button>
            ) : null}
          </div>
        ) : null}

        <div>
          {this.state.yesno ? (
            <div>
              {this.props.label3.map(l => (
                <div className="label2">{l}</div>
              ))}

              <div>
                <div className="margin20">
                  <button
                    className={`button button-line ${
                      this.state.hours === 0 ? "focused" : ""
                    }`}
                    onClick={this.select_hours(0)}
                    style={{ width: 90 }}
                  >
                    {`0-1h`}
                  </button>
                  <button
                    className={`button button-line ${
                      this.state.hours === 1 ? "focused" : ""
                    }`}
                    onClick={this.select_hours(1)}
                    style={{ width: 90 }}
                  >
                    1h
                  </button>
                  <button
                    className={`button button-line ${
                      this.state.hours === 2 ? "focused" : ""
                    }`}
                    onClick={this.select_hours(2)}
                    style={{ width: 90 }}
                  >
                    2h
                  </button>
                  <button
                    className={`button button-line ${
                      this.state.hours === 3 ? "focused" : ""
                    }`}
                    onClick={this.select_hours(3)}
                    style={{ width: 90 }}
                  >
                    3h
                  </button>
                  <button
                    className={`button button-line ${
                      this.state.hours === 4 ? "focused" : ""
                    }`}
                    onClick={this.select_hours(4)}
                    style={{ width: 90 }}
                  >
                    4h+
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div>
          {this.state.hours !== null ? (
            <div>
              {this.props.label4.map(l => (
                <div className="label2">{l}</div>
              ))}

              <div>
                <div className="margin20">
                  <button
                    className={`button button-line ${
                      this.state.frequency === 0 ? "focused" : ""
                    }`}
                    onClick={this.select_frequency(0)}
                    style={{ width: 60 }}
                  >
                    0
                  </button>
                  <button
                    className={`button button-line ${
                      this.state.frequency === 1 ? "focused" : ""
                    }`}
                    onClick={this.select_frequency(1)}
                    style={{ width: 60 }}
                  >
                    1
                  </button>
                  <button
                    className={`button button-line ${
                      this.state.frequency === 2 ? "focused" : ""
                    }`}
                    onClick={this.select_frequency(2)}
                    style={{ width: 60 }}
                  >
                    2
                  </button>
                  <button
                    className={`button button-line ${
                      this.state.frequency === 3 ? "focused" : ""
                    }`}
                    onClick={this.select_frequency(3)}
                    style={{ width: 60 }}
                  >
                    3
                  </button>
                  <button
                    className={`button button-line ${
                      this.state.frequency === 4 ? "focused" : ""
                    }`}
                    onClick={this.select_frequency(4)}
                    style={{ width: 60 }}
                  >
                    4+
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {this.state.frequency !== null || this.state.show_next ? (
          <div className="button-wrap">
            <button className="button" onClick={this.answer}>
              NEXT
            </button>
          </div>
        ) : (
          <div className="button-wrap" />
        )}
      </div>
    );
  }
}

export default QTest2;
