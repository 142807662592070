import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import "./index.css";

import Header from "./header";

class Home extends Component {
  render() {
    return (
      <div>
        <Header red noshadow />
        <div className="box-red width-100">
          <div className="inner-box">
            <div className="padding-b-40">
              <div className="para-label white title">Welcome to the 5.13:</div>
              <div className="white t-align-c ">
                Simple-X Test - Simple but not easy.
              </div>
            </div>
            <div className="width-100-max-800">
              <div className="title-left white">YOU WILL NEED</div>
              <div className="t-align-l white">
                <ul>
                  <li>20-30 minutes.</li>
                  <li>
                    A hang-board{" "}
                    <span style={{ fontSize: 11 }}>
                      (edge size options in the test are 55mm+, 33mm, 21mm,
                      18mm, 15mm, 14mm, 12mm, 10mm - not all required)
                    </span>
                    .
                  </li>
                  <li>Pull-up bar, rings, or jugs.</li>
                </ul>
              </div>

              <div className="margin20 t-align-l">
                <button
                  className="button border-white text-para"
                  onClick={() => this.props.history.push("/thetest")}
                >
                  <span>START TEST</span>
                </button>
              </div>
            </div>

            <div className="padding-b-20">
              <div className="white title">GUIDELINES</div>
            </div>
            <div className="t-align-l white">
              <ul>
                <li>
                  <strong>Honest and accurate reporting</strong>
                </li>
                <li>
                  <strong>No resting during reps</strong>
                </li>
                <li>
                  <strong>High quality reps (don't cheat)</strong>
                </li>
              </ul>
            </div>

            {/*<div className="title-left white">THE TEST</div>
            <div className="white text-para-left">
              Our goal in creating this test was to make an accessible and
              easily reproducible test for climbers around the world to see
              where they stand against other climbers; revealing weaknesses that
              can be used to guide training.
            </div>
            <div className="white text-para-left">
              The Simple-X test covers seven basic areas that have been
              determined to have a large effect on climbing ability. These
              ‘pillars’ have proven to reveal some very interesting data points
              that correlate well with actual climbing ability.
            </div>

            <div className="title-left white">GET A FRIEND</div>
            <div className="white text-para-left">
              We recommend doing the test with a friend, as it will make timing
              your efforts easier, and it will keep you accountable on the
              quality of work you do.
            </div>

            <div className="title-left white">SHARE THE TEST</div>
            <div className="white text-para-left">
              The larger the data set we can collect the more interesting the
              data becomes. So, go through it, see where you rank, and then
              share your results with your friends. Oh, and warm-up. These tests
              are simple, but not easy.
            </div>*/}

            <div className="padding-5per" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
