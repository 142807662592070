import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { getNumberWithOrdinal } from "../helpers/utility";

const display_decimal = d => {
  return parseFloat(d).toString();
};
class Percentile4 extends Component {
  getRandomInt = max => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  render() {
    let results = this.props.results;
    console.log("results3", results);
    let one_arm_percentile_50mm = [];
    let one_arm_percentile_33mm = [];
    let one_arm_percentile_21mm = [];
    let one_arm_percentile_15mm = [];

    let two_arm_user_value = "";
    let one_arm_percentile = [];
    let front_lever_percentile = [];
    let two_arm_user_index = -1;
    let front_lever_user_index = -1;
    let front_lever_user_value = "";
    let one_arm_user_index_l = -1;
    let one_arm_user_index_r = -1;
    let one_arm_user_value_l = "";
    let one_arm_user_value_r = "";

    let one_arm_50l_value = "";
    let one_arm_50l_index = -1;
    let one_arm_50r_value = "";
    let one_arm_50r_index = -1;
    let one_arm_33l_value = "";
    let one_arm_33l_index = -1;
    let one_arm_33r_value = "";
    let one_arm_33r_index = -1;
    let one_arm_21l_value = "";
    let one_arm_21l_index = -1;
    let one_arm_21r_value = "";
    let one_arm_21r_index = -1;
    let one_arm_15l_value = "";
    let one_arm_15l_index = -1;
    let one_arm_15r_value = "";
    let one_arm_15r_index = -1;

    let pointRadius = [];
    let pointRadius2 = [];
    let pointRadius3 = [];
    let pointRadius4 = [];
    let labels = [];
    for (let i = 0; i < 100; i++) {
      pointRadius[i] = 0;
      labels[i] = i;
      pointRadius2[i] = 0;
      pointRadius3[i] = 0;
      pointRadius4[i] = 0;
    }

    let pr2 = this.getRandomInt(75);
    let pr2b = this.getRandomInt(75);
    let pr3 = this.getRandomInt(75);

    if (results.one_arm_percentile_50mm) {
      one_arm_percentile_50mm = results.one_arm_percentile_50mm;
      one_arm_50l_value = results.one_arm_50l_value;
      one_arm_50l_index = results.one_arm_50l_index;
      pointRadius[one_arm_50l_index] = 5;
      one_arm_50r_value = results.one_arm_50r_value;
      one_arm_50r_index = results.one_arm_50r_index;
      pointRadius[one_arm_50r_index] = 5;
    }
    if (results.one_arm_percentile_33mm) {
      one_arm_percentile_33mm = results.one_arm_percentile_33mm;
      one_arm_33l_value = results.one_arm_33l_value;
      one_arm_33l_index = results.one_arm_33l_index;
      pointRadius2[one_arm_33l_index] = 5;
      one_arm_33r_value = results.one_arm_33r_value;
      one_arm_33r_index = results.one_arm_33r_index;
      pointRadius2[one_arm_33r_index] = 5;
    }
    if (results.one_arm_percentile_21mm) {
      one_arm_percentile_21mm = results.one_arm_percentile_21mm;
      one_arm_21l_value = results.one_arm_21l_value;
      one_arm_21l_index = results.one_arm_21l_index;
      pointRadius3[one_arm_21l_index] = 5;
      one_arm_21r_value = results.one_arm_21r_value;
      one_arm_21r_index = results.one_arm_21r_index;
      pointRadius3[one_arm_21r_index] = 5;
    }
    if (results.one_arm_percentile_15mm) {
      one_arm_percentile_15mm = results.one_arm_percentile_15mm;
      one_arm_15l_value = results.one_arm_15l_value;
      one_arm_15l_index = results.one_arm_15l_index;
      pointRadius4[one_arm_15l_index] = 5;
      one_arm_15r_value = results.one_arm_15r_value;
      one_arm_15r_index = results.one_arm_15r_index;
      pointRadius4[one_arm_15r_index] = 5;
    }

    let data_labels = [
      `55mm+ (One Arm) - L: ${
        one_arm_50l_value ? display_decimal(one_arm_50l_value) + "s, " : ""
      }${
        one_arm_50l_value
          ? getNumberWithOrdinal(one_arm_50l_index) + "%"
          : "not reported"
      } & R: ${
        one_arm_50r_value ? display_decimal(one_arm_50r_value) + "s, " : ""
      }${
        one_arm_50r_value
          ? getNumberWithOrdinal(one_arm_50r_index) + "%"
          : "not reported"
      }`,
      `33mm (One Arm) - L: ${
        one_arm_33l_value ? display_decimal(one_arm_33l_value) + "s, " : ""
      }${
        one_arm_33l_value
          ? getNumberWithOrdinal(one_arm_33l_index) + "%"
          : "not reported"
      } & R: ${
        one_arm_33r_value ? display_decimal(one_arm_33r_value) + "s, " : ""
      }${
        one_arm_33r_value
          ? getNumberWithOrdinal(one_arm_33r_index) + "%"
          : "not reported"
      }`,
      `21mm (One Arm) - L: ${
        one_arm_21l_value ? display_decimal(one_arm_21l_value) + "s, " : ""
      }${
        one_arm_21l_value
          ? getNumberWithOrdinal(one_arm_21l_index) + "%"
          : "not reported"
      } & R: ${
        one_arm_21r_value ? display_decimal(one_arm_21r_value) + "s, " : ""
      }${
        one_arm_21r_value
          ? getNumberWithOrdinal(one_arm_21r_index) + "%"
          : "not reported"
      }`,
      `15mm (One Arm) - L: ${
        one_arm_15l_value ? display_decimal(one_arm_15l_value) + "s, " : ""
      }${
        one_arm_15l_value
          ? getNumberWithOrdinal(one_arm_15l_index) + "%"
          : "not reported"
      } & R: ${
        one_arm_15r_value ? display_decimal(one_arm_15r_value) + "s, " : ""
      }${
        one_arm_15r_value
          ? getNumberWithOrdinal(one_arm_15r_index) + "%"
          : "not reported"
      }`
    ];

    return (
      <Line
        data={{
          labels: labels,
          datasets: [
            {
              label: data_labels[0],
              data: one_arm_percentile_50mm,
              pointStyle: "rectRounded",
              pointRadius: pointRadius,
              pointStyle: "circle",
              fill: false,
              backgroundColor: "#9fcc3b",
              borderColor: "#9fcc3b",
              borderWidth: 1,
              lineTension: 1
            },
            {
              label: data_labels[1],
              data: one_arm_percentile_33mm,
              pointStyle: "rectRounded",
              pointRadius: pointRadius2,
              fill: false,
              backgroundColor: "#0594b9",
              borderColor: "#0594b9",
              borderWidth: 1
            },
            {
              label: data_labels[2],
              data: one_arm_percentile_21mm,
              pointStyle: "rectRounded",
              pointRadius: pointRadius3,
              fill: false,
              backgroundColor: "#ccb637",
              borderColor: "#ccb637",
              borderWidth: 1
            },
            {
              label: data_labels[3],
              data: one_arm_percentile_15mm,
              pointStyle: "rectRounded",
              pointRadius: pointRadius4,
              fill: false,
              backgroundColor: "#ff0000",
              borderColor: "#ff0000",
              borderWidth: 1
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          aspectRatio: 1,
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  // Include a dollar sign in the ticks
                  callback: function(value, index, values) {
                    if ([0, 25, 50, 75, 99].includes(index)) return value;
                    if (
                      [
                        one_arm_50l_index,
                        one_arm_50r_index,
                        one_arm_33l_index,
                        one_arm_33r_index,
                        one_arm_21l_index,
                        one_arm_21r_index,
                        one_arm_15l_index,
                        one_arm_15r_index
                      ].includes(index)
                    )
                      return "";
                    else return null;
                  }
                }
              }
            ]
          },
          legend: {
            position: "bottom",
            display: true,
            labels: {
              fontColor: "rgb(0,0,0)"
            },
            labels: {
              boxWidth: 20
            },
            align: "start"
          },
          title: {
            display: true,
            text: this.props.title
          },
          tooltips: {
            enabled: true,
            callbacks: {
              title: () => "",
              label: (tooltipItem, data) =>
                data.datasets[tooltipItem.datasetIndex].label
            }
          }
        }}
      />
    );
  }
}

export default Percentile4;
