import React, { Component } from "react";
import { withRouter, useLocation } from "react-router-dom";

import qs from "qs";

import * as requests from "../helpers/requests";
import { api_url } from "../environment";

import "../index.css";
import "./style.css";

import Header from "../header";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

class Thanks extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let row_count = 5;
    // let query = qs.parse(this.props.location.search.slice(1));
    // console.log(query.id);
    return (
      <div>
        <Header />

        <div className="flex">
          <div className="label margin20 t-align-c">Thanks!</div>
          <p className="label2 t-align-c margin-b-0">
            We appreciate the response!
          </p>

          <div className="noflex width-100">
            <div className="button-wrap margin20">
              <button
                className="button"
                onClick={() =>
                  (window.location = "https://www.howtoclimb513.com")
                }
              >
                HOME
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Thanks);
