import React, { Component } from "react";

class QTest1 extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value: "",
      value_l: "",
      value_r: "",
      edge_selected: [],
      edges: {},
      yesno: false
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);
    if (this.props.accessor) {
      let {
        meta = [],
        value = "",
        value_l = "",
        value_r = "",
        edges = {},
        edge_selected = []
      } = this.props.accessor(this.props.answers);
      this.setState({ value, value_l, value_r, edge_selected, edges });
    } else {
      if (this.props.answers[this.props.index]) {
        let {
          meta = [],
          value = "",
          value_l = "",
          value_r = ""
        } = this.props.answers[this.props.index];
        // console.log("here2", meta);
        this.setState({ value, value_l, value_r, edge_selected: meta });
      }
    }
  }

  select_edge = size => () => {
    let edge_selected = [...this.state.edge_selected];
    let index = this.state.edge_selected.findIndex(e => e === size);
    if (index === -1) {
      edge_selected.push(size);
    } else {
      edge_selected = edge_selected.filter(e => e !== size);
    }
    // console.log(edge_selected);
    this.setState({ edge_selected });
  };
  set_value = edge => e => {
    let value = e.target.value;
    this.setState({ edges: { ...this.state.edges, [edge]: value } });
  };

  set_button = () => {
    this.setState({ yesno: true });
    this.props.callback1(true);
  };
  keydown = event => {
    var KeyID = event.keyCode;
    switch (KeyID) {
      // case 8:
      //   let value = this.state.value.slice(0, -2) + "s";
      //   if (value === "s") value = "";
      //   this.setState({ value });
      //   break;
      case 13:
        this.answer();
        break;
      default:
        break;
    }
  };

  set_value_uni = side => e => {
    let value = e.target.value;
    if (side === "left") {
      this.setState({ value_l: value });
    } else {
      this.setState({ value_r: value });
    }
  };

  answer = () => {
    if (this.props.unilateral && this.state.edge_selected.length > 0) {
      // console.log(
      //   "we are in unilateral",
      //   JSON.stringify(this.state.edge_selected),
      //   JSON.stringify(this.state.edges)
      // );
      this.props.callback2(
        JSON.stringify(this.state.edge_selected),
        JSON.stringify(this.state.edges)
      );
      this.props.handle_next();
    } else if (this.props.unilateral) {
      this._input2.focus();
    } else {
      this.props.callback2(
        JSON.stringify(this.state.edge_selected),
        JSON.stringify(this.state.edges)
      );
      this.props.handle_next();
    }
  };

  render() {
    // console.log("text1 state", this.state);
    return (
      <div>
        {this.state.edge_selected.length < 2 ? (
          <React.Fragment>
            {this.props.title ? (
              <div className="label2 bold">
                {this.props.title.toUpperCase()}
              </div>
            ) : null}
            <div className="label">{this.props.text}</div>

            <div className="margin20">
              {this.props.label2.map(l => (
                <div className="label2">{l}</div>
              ))}
            </div>
          </React.Fragment>
        ) : null}
        <div>
          <div className="margin-t-20">
            {this.props.unilateral ? (
              <button
                className={`button button-line ${
                  this.state.edge_selected.includes("50mm+")
                    ? "focused"
                    : "un-focused"
                }`}
                onClick={this.select_edge("50mm+")}
              >
                55mm+
              </button>
            ) : null}

            {this.props.unilateral ? (
              <button
                className={`button button-line ${
                  this.state.edge_selected.includes("33mm")
                    ? "focused"
                    : "un-focused"
                }`}
                onClick={this.select_edge("33mm")}
              >
                33mm
              </button>
            ) : null}

            {this.props.unilateral ? (
              <button
                className={`button button-line ${
                  this.state.edge_selected.includes("21mm")
                    ? "focused"
                    : "un-focused"
                }`}
                onClick={this.select_edge("21mm")}
              >
                21mm
              </button>
            ) : null}

            {this.props.unilateral ? (
              <button
                className={`button button-line ${
                  this.state.edge_selected.includes("15mm")
                    ? "focused"
                    : "un-focused"
                }`}
                onClick={this.select_edge("15mm")}
              >
                15mm
              </button>
            ) : null}

            {!this.props.unilateral ? (
              <button
                className={`button button-line ${
                  this.state.edge_selected.includes("18mm")
                    ? "focused"
                    : "un-focused"
                }`}
                onClick={this.select_edge("18mm")}
              >
                18mm
              </button>
            ) : null}

            {!this.props.unilateral ? (
              <button
                className={`button button-line ${
                  this.state.edge_selected.includes("14mm")
                    ? "focused"
                    : "un-focused"
                }`}
                onClick={this.select_edge("14mm")}
              >
                14mm
              </button>
            ) : null}

            {!this.props.unilateral ? (
              <button
                className={`button button-line ${
                  this.state.edge_selected.includes("12mm")
                    ? "focused"
                    : "un-focused"
                }`}
                onClick={this.select_edge("12mm")}
              >
                12mm
              </button>
            ) : null}

            {!this.props.unilateral ? (
              <button
                className={`button button-line ${
                  this.state.edge_selected.includes("10mm")
                    ? "focused"
                    : "un-focused"
                }`}
                onClick={this.select_edge("10mm")}
              >
                10mm
              </button>
            ) : null}
          </div>

          <div>
            {this.state.edge_selected.length > 0 ? (
              <div>
                {this.props.unilateral ? (
                  <div className="flex  margin-t-20">
                    {this.state.edge_selected.length < 2 ? (
                      <React.Fragment>
                        <div className="flex uni-input-wrapper">
                          <input
                            type={`${this.props.numeric ? "number" : "text"}`}
                            className="input"
                            name={this.props.name}
                            ref={inp => {
                              this._input = inp;
                            }}
                            value={
                              this.state.edges[
                                this.state.edge_selected[0] + "l"
                              ]
                            }
                            placeholder={this.props.placeholderl}
                            onChange={this.set_value(
                              this.state.edge_selected[0] + "l"
                            )}
                            onKeyDown={this.keydown}
                          />
                        </div>

                        <div className="flex uni-input-wrapper">
                          <input
                            type={`${this.props.numeric ? "number" : "text"}`}
                            className="input"
                            name={this.props.name}
                            ref={inp => {
                              this._input2 = inp;
                            }}
                            value={
                              this.state.edges[
                                this.state.edge_selected[0] + "r"
                              ]
                            }
                            placeholder={this.props.placeholderr}
                            onChange={this.set_value(
                              this.state.edge_selected[0] + "r"
                            )}
                            onKeyDown={this.keydown}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="flex row">
                          <span className="table sec-width"></span>
                          <span className="table sec-width">L</span>
                          <span className="table sec-width">R</span>
                        </div>
                        {this.state.edge_selected.map(edge => (
                          <div className="flex row">
                            <span className="table sec-width">{edge}</span>

                            <input
                              type={`${this.props.numeric ? "number" : "text"}`}
                              className="input no-top-margin sec-width"
                              name={this.props.name}
                              ref={inp => {
                                this._input = inp;
                              }}
                              value={this.state.edges[edge + "l"]}
                              placeholder={"sec"}
                              onChange={this.set_value(edge + "l")}
                              onKeyDown={this.keydown}
                            />

                            <input
                              type={`${this.props.numeric ? "number" : "text"}`}
                              className="input no-top-margin sec-width"
                              name={this.props.name}
                              ref={inp => {
                                this._input2 = inp;
                              }}
                              value={this.state.edges[edge + "r"]}
                              placeholder={"sec"}
                              onChange={this.set_value(edge + "r")}
                              onKeyDown={this.keydown}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  <div className="flex">
                    {this.state.edge_selected.length < 2 ? (
                      <input
                        type={`${this.props.numeric ? "number" : "text"}`}
                        className="input"
                        name={this.props.name}
                        ref={inp => {
                          this._input = inp;
                        }}
                        value={this.state.edges[this.state.edge_selected[0]]}
                        placeholder={this.props.placeholder}
                        onChange={this.set_value(this.state.edge_selected[0])}
                        onKeyDown={this.keydown}
                      />
                    ) : (
                      <React.Fragment>
                        {this.state.edge_selected.map(edge => (
                          <div className="flex row">
                            <span className="table t-align-r">{edge}</span>
                            <input
                              type={`${this.props.numeric ? "number" : "text"}`}
                              className="input table no-top-margin sec-width"
                              name={this.props.name}
                              ref={inp => {
                                this._input = inp;
                              }}
                              value={this.state.edges[edge]}
                              placeholder={"sec"}
                              onChange={this.set_value(edge)}
                              onKeyDown={this.keydown}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>

          {Object.keys(this.state.edges).length > 0 ||
          (this.state.value_l.length > 0 && this.state.value_r.length > 0) ? (
            <div className="button-wrap">
              <button className="button" onClick={this.answer}>
                NEXT
              </button>
            </div>
          ) : (
            <div className="button-wrap" />
          )}
          {false && this.props.skip ? (
            <div className="skip" onClick={this.props.handle_next}>
              {" "}
              SKIP{" "}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default QTest1;
