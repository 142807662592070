import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { getNumberWithOrdinal } from "../helpers/utility";

const display_decimal = d => {
  return parseFloat(d).toString();
};
class Percentile3 extends Component {
  getRandomInt = max => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  render() {
    let results = this.props.results;
    console.log("results3", results);
    let two_arm_percentile_18mm = [];
    let two_arm_percentile_14mm = [];
    let two_arm_percentile_12mm = [];
    let two_arm_percentile_10mm = [];
    let front_lever_percentile = [];
    let two_arm_user_index = -1;
    let front_lever_user_index = -1;
    let front_lever_user_value = "";

    let two_arm_18_value = "";
    let two_arm_18_index = -1;
    let two_arm_14_value = "";
    let two_arm_14_index = -1;
    let two_arm_12_value = "";
    let two_arm_12_index = -1;
    let two_arm_10_value = "";
    let two_arm_10_index = -1;

    let pointRadius = [];
    let pointRadius2 = [];
    let pointRadius3 = [];
    let pointRadius4 = [];
    let pointRadius5 = [];
    let labels = [];
    for (let i = 0; i < 100; i++) {
      pointRadius[i] = 0;
      labels[i] = i;
      pointRadius2[i] = 0;
      pointRadius3[i] = 0;
      pointRadius4[i] = 0;
      pointRadius5[i] = 0;
    }

    if (results.two_arm_percentile_18mm) {
      two_arm_percentile_18mm = results.two_arm_percentile_18mm;
      two_arm_18_value = results.two_arm_18_value;
      two_arm_18_index = results.two_arm_18_index;
      pointRadius[two_arm_18_index] = 5;
    }
    if (results.two_arm_percentile_14mm) {
      two_arm_percentile_14mm = results.two_arm_percentile_14mm;
      two_arm_14_value = results.two_arm_14_value;
      two_arm_14_index = results.two_arm_14_index;
      pointRadius2[two_arm_14_index] = 5;
    }
    if (results.two_arm_percentile_12mm) {
      two_arm_percentile_12mm = results.two_arm_percentile_12mm;
      two_arm_12_value = results.two_arm_12_value;
      two_arm_12_index = results.two_arm_12_index;
      pointRadius3[two_arm_12_index] = 5;
    }
    if (results.two_arm_percentile_10mm) {
      two_arm_percentile_10mm = results.two_arm_percentile_10mm;
      two_arm_10_value = results.two_arm_10_value;
      two_arm_10_index = results.two_arm_10_index;
      pointRadius4[two_arm_10_index] = 5;
    }

    if (results.front_lever_percentile) {
      front_lever_percentile = JSON.parse(results.front_lever_percentile);
      front_lever_user_index = results.front_lever_user_index;
      front_lever_user_value = results.front_lever_user_value;
      pointRadius5[front_lever_user_index] = 5;
    }

    let data_labels = [
      `18mm (Two Arms) - ${
        two_arm_18_value ? display_decimal(two_arm_18_value) + "s, " : ""
      }${
        two_arm_18_value
          ? getNumberWithOrdinal(two_arm_18_index) + "%"
          : "not reported"
      }`,
      `14mm (Two Arms) - ${
        two_arm_14_value ? display_decimal(two_arm_14_value) + "s, " : ""
      }${
        two_arm_14_value
          ? getNumberWithOrdinal(two_arm_14_index) + "%"
          : "not reported"
      }`,
      `12mm (Two Arms) - ${
        two_arm_12_value ? display_decimal(two_arm_12_value) + "s, " : ""
      }${
        two_arm_12_value
          ? getNumberWithOrdinal(two_arm_12_index) + "%"
          : "not reported"
      }`,
      `10mm (Two Arms) - ${
        two_arm_10_value ? display_decimal(two_arm_10_value) + "s, " : ""
      }${
        two_arm_10_value
          ? getNumberWithOrdinal(two_arm_10_index) + "%"
          : "not reported"
      }`,
      `Front Lever - ${
        front_lever_user_value
          ? display_decimal(front_lever_user_value) + "s, "
          : ""
      }${
        front_lever_user_index
          ? getNumberWithOrdinal(front_lever_user_index) + "%"
          : "not reported"
      }`
    ];

    return (
      <Line
        data={{
          labels: labels,
          datasets: [
            {
              label: data_labels[0],
              data: two_arm_percentile_18mm,
              pointStyle: "rectRounded",
              pointRadius: pointRadius,
              pointStyle: "circle",
              fill: false,
              backgroundColor: "#9fcc3b",
              borderColor: "#9fcc3b",
              borderWidth: 1,
              lineTension: 1
            },
            {
              label: data_labels[1],
              data: two_arm_percentile_14mm,
              pointStyle: "rectRounded",
              pointRadius: pointRadius2,
              fill: false,
              backgroundColor: "#0594b9",
              borderColor: "#0594b9",
              borderWidth: 1
            },
            {
              label: data_labels[2],
              data: two_arm_percentile_12mm,
              pointStyle: "rectRounded",
              pointRadius: pointRadius3,
              fill: false,
              backgroundColor: "#ccb637",
              borderColor: "#ccb637",
              borderWidth: 1
            },
            {
              label: data_labels[3],
              data: two_arm_percentile_10mm,
              pointStyle: "rectRounded",
              pointRadius: pointRadius4,
              fill: false,
              backgroundColor: "#ff0000",
              borderColor: "#ff0000",
              borderWidth: 1
            },
            {
              label: data_labels[4],
              data: front_lever_percentile,
              pointStyle: "rectRounded",
              pointRadius: pointRadius5,
              fill: false,
              backgroundColor: "#243e4c",
              borderColor: "#243e4c",
              borderWidth: 1,
              borderDash: [5, 5]
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          aspectRatio: 1,
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  // Include a dollar sign in the ticks
                  callback: function(value, index, values) {
                    if ([0, 25, 50, 75, 99].includes(index)) return value;
                    if (
                      [
                        two_arm_18_index,
                        two_arm_14_index,
                        two_arm_12_index,
                        two_arm_10_index,
                        front_lever_user_index
                      ].includes(index)
                    )
                      return "";
                    else return null;
                  }
                }
              }
            ]
          },
          legend: {
            position: "bottom",
            display: true,
            labels: {
              fontColor: "rgb(0,0,0)"
            },
            labels: {
              boxWidth: 20
            },
            align: "start"
          },
          title: {
            display: true,
            text: this.props.title
          },
          tooltips: {
            enabled: true,
            callbacks: {
              title: () => "",
              label: (tooltipItem, data) =>
                data.datasets[tooltipItem.datasetIndex].label
            }
          }
        }}
      />
    );
  }
}

export default Percentile3;
