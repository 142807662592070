import React, { Component } from "react";
import "whatwg-fetch";

import { withRouter } from "react-router-dom";
import moment from "moment";

import { FacebookShareButton, FacebookIcon } from "react-share";

import * as requests from "./helpers/requests";
import { api_url } from "./environment";

import Percentile3 from "./data/percentile3";
import Percentile4 from "./data/percentile4";
import Reps from "./data/reps";
import GradeBar from "./data/gradebar";
import BarTwoArmHang from "./data/bar_two_arm_hang";
import BarOneArmHang from "./data/bar_one_arm_hang";

import "./index.css";

import Header from "./header_nav";

class Results3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      confirmed: false,
      width: window.innerWidth,
      height: window.innerHeight,
      survey: {},
      percent: {},
      for_time: null,
      for_reps: null,
      grade_compare: null,
      header_nav_tab: "sport"
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  async componentDidMount() {
    let survey_id = this.props.match.params.token;
    if (survey_id) {
      let url = `${api_url}/results_percent/${survey_id}/`;
      requests.get(url).then(percent => this.setState({ percent }));
      url = `${api_url}/results/${survey_id}/${
        this.props.share ? "share/" : ""
      }`;
      requests.get(url).then(survey => this.setState({ survey }));
      url = `${api_url}/results_for_time/${survey_id}/`;
      requests.get(url).then(for_time => this.setState({ for_time }));
      url = `${api_url}/results_for_reps/${survey_id}/`;
      requests.get(url).then(for_reps => this.setState({ for_reps }));
      url = `${api_url}/results_climbing_comparison_grade/${survey_id}/`;
      requests.get(url).then(grade_compare => this.setState({ grade_compare }));
    }
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.updateDimensions);
  }

  confirm = () => {
    let url = `${api_url}/confirm_email/`;
    let body = {
      email: this.state.value,
      survey_id: this.survey.id
    };
    requests.post(url, JSON.stringify(body));

    this.setState({ confirmed: true });
  };

  patch_survey = () => {
    if (this.survey.id) {
      let url = `${api_url}/survey/${this.survey.id}/`;
      let body = {
        ...this.survey,
        email: this.state.value,
        email_confirmed: true
      };
      requests
        .patch(url, JSON.stringify(body))
        .then(survey => (this.survey = survey));
    }
  };
  set_nav_state = header_nav_tab => () => {
    this.setState({ header_nav_tab });
  };

  render() {
    let percent = this.state.percent;
    let survey_id = this.props.match.params.token;

    let grade_bar_title = "";
    if (this.state.grade_compare) {
      if (
        this.state.grade_compare.your_comparison[this.state.header_nav_tab]
          .label ===
        this.state.grade_compare.next_grade[this.state.header_nav_tab].label
      )
        grade_bar_title = `You vs ${this.state.grade_compare.next_grade[this.state.header_nav_tab].label} Climbers`;
      else
        grade_bar_title = `${this.state.grade_compare.your_comparison[this.state.header_nav_tab].label} vs ${this.state.grade_compare.next_grade[this.state.header_nav_tab].label} Climbers`;
    }
    return (
      <div className="main">
        <Header nav={this.state.header_nav_tab} set_nav={this.set_nav_state} />

        <div className="dashboard-gray">
          <div className="flex">
            <div className="inner-box">
              <div className="label margin-t-20 t-align-c">
                {this.state.survey.name}
              </div>

              <div className="label3 margin-t-20 t-align-c">
                {this.state.header_nav_tab === "sport"
                  ? "Sport Climbing"
                  : "Bouldering"}
                <br />
                Live Data Comparison
              </div>

              <div className="t-align-c">
                {!this.props.share ? (
                  <div className="t-align-c margin-t-30">
                    <button
                      className="button"
                      onClick={() => {
                        window.location = "https://data.howtoclimb513.com/";
                      }}
                    >
                      TAKE THE TEST
                    </button>
                  </div>
                ) : null}
                <FacebookShareButton
                  quote="Check out my test results from How To Climb 5.13.com"
                  hashtag="#climbing"
                  url={`${
                    this.props.share
                      ? "https://data.howtoclimb513.com/results/" +
                        survey_id +
                        "/"
                      : "https://www.howtoclimb513.com"
                  }`}
                  onShareWindowClose={() => {
                    let url = `${api_url}/survey_share_action/${this.state.survey.id}/`;
                    requests.post(
                      url,
                      JSON.stringify({ action: "fb-window-close" })
                    );
                  }}
                >
                  <div className="fb-button">
                    <FacebookIcon size={40} round />
                    <span className="fb-button-text">POST RESULTS</span>
                  </div>
                </FacebookShareButton>
              </div>
              {this.state.grade_compare ? (
                <div className="flex dashboard-card">
                  <div className="label3 label3-holder">
                    {`${grade_bar_title}`}
                  </div>
                  <div className="width-100-max-800">
                    <GradeBar
                      results={this.state.grade_compare}
                      selection={this.state.header_nav_tab}
                    />
                  </div>
                </div>
              ) : null}

              <div className="label2 t-align-c width-100p margin-t-30">
                {percent.show_front_lever ||
                percent.show_user_cardio ||
                percent.show_user_flexibility ? (
                  <span>
                    Compared to other climbers that have taken the test, you are
                    in the:
                  </span>
                ) : (
                  <span>""</span>
                )}
              </div>
              <div className="flex row">
                {this.state.percent.show_front_lever ? (
                  <div className="percent-wrapper dashboard-card-small">
                    <div className="percent">
                      {this.state.percent.front_lever}%
                    </div>
                    <div className="percent-label">
                      {this.state.percent.front_lever_text}
                    </div>
                  </div>
                ) : null}

                {this.state.percent.show_user_cardio ? (
                  <div className="percent-wrapper dashboard-card-small">
                    <div className="percent">{this.state.percent.cardio}%</div>
                    <div className="percent-label">
                      {this.state.percent.cardio_text}
                    </div>
                  </div>
                ) : null}

                {this.state.percent.show_user_flexibility ? (
                  <div className="percent-wrapper dashboard-card-small">
                    <div className="percent">
                      {this.state.percent.flexibility}%
                    </div>
                    <div className="percent-label">
                      {this.state.percent.flexibility_text}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="flex">
                <div className="flex dashboard-card">
                  {this.state.grade_compare ? (
                    <React.Fragment>
                      <div className="label3 label3-holder">
                        {"Your Standing on Two Arm Hangs and Front Lever"}
                      </div>

                      <div className="width-100-max-800">
                        <BarTwoArmHang
                          results={this.state.grade_compare}
                          selection={this.state.header_nav_tab}
                        />
                      </div>
                    </React.Fragment>
                  ) : null}

                  {this.state.for_time ? (
                    <div className="width-100-max-800 mobile-min-h-350 margin-t-20">
                      <Percentile3
                        title="Time(s) vs Percentile"
                        results={this.state.for_time}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="dashboard-card">
                  <div className="label3">
                    {"Your Standing on One Arm Hangs"}
                  </div>
                  {this.state.grade_compare ? (
                    <div className="flex">
                      <div className="width-100-max-800">
                        <BarOneArmHang
                          results={this.state.grade_compare}
                          selection={this.state.header_nav_tab}
                        />
                      </div>
                    </div>
                  ) : null}

                  {this.state.for_time ? (
                    <div className="width-100-max-800 mobile-min-h-300 margin-t-20">
                      <Percentile4
                        title="Time(s) vs Percentile"
                        results={this.state.for_time}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="dashboard-card">
                  <div className="label3">
                    {"Your Standing on the Rep Based Tests"}
                  </div>
                  {this.state.for_reps ? (
                    <div className="width-100-max-800">
                      <Reps
                        title="Reps vs Percentile"
                        results={this.state.for_reps}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="margin-t-10 t-align-c">
            Results Entered{" "}
            {moment(this.state.survey.date).format("MMM DD, YYYY")}
          </div>

          {this.props.share ? (
            <div className="t-align-c margin-t-30">
              <button
                className="button"
                onClick={() => {
                  window.location = "https://data.howtoclimb513.com/thetest/";
                }}
              >
                RETAKE TEST
              </button>
            </div>
          ) : (
            <div className="t-align-c margin-t-30">
              <button
                className="button"
                onClick={() => {
                  window.location = "https://data.howtoclimb513.com/";
                }}
              >
                TAKE THE TEST
              </button>
            </div>
          )}
          <div className="padding-5per" />
        </div>
      </div>
    );
  }
}

export default withRouter(Results3);
