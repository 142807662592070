import React, { Component } from "react";

class QChoice3 extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value: "",
      show_next: false
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);

    if (
      this.props.survey_key &&
      this.props.survey[this.props.survey_key] !== null
    ) {
      this.setState({
        value: this.props.survey[this.props.survey_key],
        show_next: this.props.survey[this.props.survey_key] !== 0
      });
    } else if (this.props.answers[this.props.index]) {
      let { value } = this.props.answers[this.props.index];
      this.setState({ value, show_next: true });
    }
  }

  onKeyPress = e => {
    if (e.keyCode === 13) {
      this.props.handle_next();
    }
  };

  answer = value => () => {
    this.props.callback(value);
    this.props.handle_next();
  };

  render() {
    return (
      <div>
        {this.props.title ? (
          <div className="label2 bold">{this.props.title.toUpperCase()}</div>
        ) : null}
        <div className="label">{this.props.text}</div>

        <div>
          <div className="margin-tb-20">
            {this.props.vert ? (
              <React.Fragment>
                <div>
                  <button
                    className={`button choice-button ${
                      this.state.value === 1 ? "focused" : ""
                    }`}
                    onClick={this.answer(1)}
                    style={{ width: this.props.button_width }}
                  >
                    {this.props.choices[0]}
                  </button>
                </div>
                <div>
                  <button
                    className={`button margin20 choice-button ${
                      this.state.value === 2 ? "focused" : ""
                    }`}
                    onClick={this.answer(2)}
                    style={{ width: this.props.button_width }}
                  >
                    {this.props.choices[1]}
                  </button>
                </div>
                <div>
                  <button
                    className={`button choice-button ${
                      this.state.value === 3 ? "focused" : ""
                    }`}
                    onClick={this.answer(3)}
                    style={{ width: this.props.button_width }}
                  >
                    {this.props.choices[2]}
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  className={`button choice-button ${
                    this.state.value === 1 ? "focused" : ""
                  }`}
                  onClick={this.answer(1)}
                  style={{ width: this.props.button_width }}
                >
                  {this.props.choices[0]}
                </button>

                <button
                  className={`button margin20 choice-button ${
                    this.state.value === 2 ? "focused" : ""
                  }`}
                  onClick={this.answer(2)}
                  style={{ width: this.props.button_width }}
                >
                  {this.props.choices[1]}
                </button>

                <button
                  className={`button choice-button ${
                    this.state.value === 3 ? "focused" : ""
                  }`}
                  onClick={this.answer(3)}
                  style={{ width: this.props.button_width }}
                >
                  {this.props.choices[2]}
                </button>
              </React.Fragment>
            )}
          </div>
        </div>

        {this.state.show_next ? (
          <div className="button-wrap">
            <button className="button" onClick={this.props.handle_next}>
              NEXT
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default QChoice3;
