import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import * as requests from "./helpers/requests";
import { api_url } from "./environment";

import "./index.css";

import Header from "./header";

class EmailConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", confirmed: false };
  }

  componentDidMount() {
    let token = this.props.match.params.token;
    let url = `${api_url}/confirm_email/${token}/`;
    let body = {};
    requests.post(url, JSON.stringify(body));
  }

  confirm = () => {
    window.location = "https://www.howtoclimb513.com";
  };

  render() {
    return (
      <div>
        <Header />

        <div className="flex">
          <div className="label margin20 t-align-c">Thanks!</div>
          <div className="flex">
            <div className="label2 width-50">
              Your email has been confirmed.
            </div>
            <div className="noflex width-100">
              <div className="button-wrap margin20">
                <button className="button" onClick={this.confirm}>
                  OKAY
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmailConfirmation);
