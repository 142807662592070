import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class GradeBar extends Component {
  chartRef = React.createRef();

  render() {
    let r = this.props.results;
    let selection = this.props.selection;
    let comparison = r.your_comparison[selection];
    let next_grade = r.next_grade[selection];

    let hang_2_arm = r.you.hang_2_arm_18;
    let hang_2_arm_edge_size = "18mm";
    if (r.you.hang_2_arm_10 > 0) {
      hang_2_arm = r.you.hang_2_arm_10;
      hang_2_arm_edge_size = "10mm";
    } else if (r.you.hang_2_arm_12 > 0) {
      hang_2_arm = r.you.hang_2_arm_12;
      hang_2_arm_edge_size = "12mm";
    } else if (r.you.hang_2_arm_14 > 0) {
      hang_2_arm = r.you.hang_2_arm_14;
      hang_2_arm_edge_size = "14mm";
    }

    let hang_2_arm_avg = comparison.hang_2_arm_18;
    if (hang_2_arm_edge_size.includes("10"))
      hang_2_arm_avg = comparison.hang_2_arm_10;
    else if (hang_2_arm_edge_size.includes("12"))
      hang_2_arm_avg = comparison.hang_2_arm_12;
    else if (hang_2_arm_edge_size.includes("14"))
      hang_2_arm_avg = comparison.hang_2_arm_14;

    let hang_1_arm = r.you.hang_1_arm_50;
    let hang_1_arm_edge_size = "55mm+";
    if (r.you.hang_1_arm_15 > 0) {
      hang_1_arm = r.you.hang_1_arm_15;
      hang_1_arm_edge_size = "15mm";
    } else if (r.you.hang_1_arm_21 > 0) {
      hang_1_arm = r.you.hang_1_arm_21;
      hang_1_arm_edge_size = "21mm";
    } else if (r.you.hang_1_arm_33 > 0) {
      hang_1_arm = r.you.hang_1_arm_33;
      hang_1_arm_edge_size = "33mm";
    }

    let hang_1_arm_avg = comparison.hang_1_arm_50;
    if (hang_1_arm_edge_size.includes("15"))
      hang_1_arm_avg = comparison.hang_1_arm_15;
    else if (hang_1_arm_edge_size.includes("21"))
      hang_1_arm_avg = comparison.hang_1_arm_21;
    else if (hang_1_arm_edge_size.includes("33"))
      hang_1_arm_avg = comparison.hang_1_arm_33;

    let hang_2_arm_avg_next = next_grade.hang_2_arm_18;
    if (hang_2_arm_edge_size.includes("10"))
      hang_2_arm_avg_next = next_grade.hang_2_arm_10;
    else if (hang_2_arm_edge_size.includes("12"))
      hang_2_arm_avg_next = next_grade.hang_2_arm_12;
    else if (hang_2_arm_edge_size.includes("14"))
      hang_2_arm_avg_next = next_grade.hang_2_arm_14;

    let hang_1_arm_avg_next = next_grade.hang_1_arm_50;
    if (hang_1_arm_edge_size.includes("15"))
      hang_1_arm_avg_next = next_grade.hang_1_arm_15;
    else if (hang_1_arm_edge_size.includes("21"))
      hang_1_arm_avg_next = next_grade.hang_1_arm_21;
    else if (hang_1_arm_edge_size.includes("33"))
      hang_1_arm_avg_next = next_grade.hang_1_arm_33;

    let datasets = [
      {
        label: "Your Results",
        barThickness: 15,
        data: [
          hang_2_arm,
          hang_1_arm,
          r.you.front_lever,
          r.you.knee_ups,
          r.you.pull_ups,
          r.you.squat_1_leg,
          r.you.push_ups
        ],
        pointStyle: "rectRounded",
        pointRadius: [0, 10, 0, 10, 0],
        fill: false,
        backgroundColor: "#ff0000",
        borderColor: "#ff0000"
      },
      {
        label: `${comparison.label} Average`,
        barThickness: 15,
        data: [
          hang_2_arm_avg,
          hang_1_arm_avg,
          comparison.front_lever,
          comparison.knee_ups,
          comparison.pull_ups,
          comparison.squat_1_leg,
          comparison.push_ups
        ],
        pointStyle: "rectRounded",
        pointRadius: [0, 10, 0, 10, 0],
        fill: false,
        backgroundColor: "#bbb",
        borderColor: "#bbb"
      },
      {
        label: `${next_grade.label} Average`,
        barThickness: 15,
        data: [
          hang_2_arm_avg_next,
          hang_1_arm_avg_next,
          next_grade.front_lever,
          next_grade.knee_ups,
          next_grade.pull_ups,
          next_grade.squat_1_leg,
          next_grade.push_ups
        ],
        pointStyle: "rectRounded",
        pointRadius: [0, 10, 0, 10, 0],
        fill: false,
        backgroundColor: "#243e4c",
        borderColor: "#243e4c"
      }
    ];

    //pull out the redundant dataset when the grade compare is the same for next_grade
    if (comparison.label === next_grade.label) datasets.splice(2);

    return (
      <Bar
        data={{
          labels: [
            `2 Arm (${hang_2_arm_edge_size})`,
            `1 Arm (${hang_1_arm_edge_size})`,
            "Front Lever",
            "Knee Ups",
            "Pull Ups",
            "1 Leg Squat",
            "Push Ups"
          ],
          datasets
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                categorySpacing: 1.0
              }
            ]
          },
          legend: {
            position: "bottom",
            display: true,

            labels: {
              boxWidth: 20
            },
            align: "start"
          },
          title: {
            display: false,
            text: "test"
          }
        }}
      />
    );
  }
}

export default GradeBar;
