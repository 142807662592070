import React, { Component } from "react";

import Logo from "./assets/logo.png";
import LogoWhite from "./assets/logo_white.png";

class Header extends Component {
  render() {
    return (
      <div
        className={`${this.props.fixed ? "header-fixed" : "header-reactive"} ${
          this.props.red ? "red-bgnd" : "white-bgnd"
        }`}
      >
        <div className={`logo-wrap ${this.props.noshadow ? "no-shadow" : ""}`}>
          <img
            src={this.props.red ? LogoWhite : Logo}
            width="150px"
            height="50px"
            alt="Climb 5.13"
            className="logo"
          />
        </div>
      </div>
    );
  }
}

export default Header;
