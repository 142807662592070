import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-solid-svg-icons";

import { withRouter } from "react-router-dom";

import "./index.css";

import Header from "./header";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consent1: true,
      consent2: true,
      consent3: true
    };
  }

  render() {
    return (
      <div className="box-red">
        <Header red />
        <div className="flex width-100">
          <div className="inner-box">
            <div className="box">
              <div className="white text-para-left margin-t-20">
                Do you agree to be part of the Simple-X Study, and that your
                data will be used in our collection. You will remain anonymous
                in any findings we make, and your privacy will be guaranteed. We
                may also contact you if the test changes or evolves to retry it
                at some point in the future.
              </div>
              <div className="width-70">
                <div className="label2 white margin20">
                  <FontAwesomeIcon
                    icon={this.state.consent1 ? faCheckSquare : faSquare}
                    className="checkbox"
                    onClick={() =>
                      this.setState({ consent1: !this.state.consent1 })
                    }
                  />
                  I agree
                </div>
              </div>

              {this.state.consent1 &&
              this.state.consent2 &&
              this.state.consent3 ? (
                <button
                  className="button border-white margin40"
                  onClick={() => this.props.history.push("/thetest")}
                >
                  <span>START</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
