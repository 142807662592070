import React, { Component } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Test from "./Test";
import Home from "./Home";
import Report from "./Report";
import Consent from "./Consent";
import Rules from "./Rules";
import EmailConfirmation from "./email_confirmation";
import Results from "./Results";
import TrainingSurvey from "./pages/training_survey";
import Thanks from "./pages/thanks";
import EmailUnsubscribe from "./pages/email_unsubscribe";
import UnsubscribeSuccess from "./pages/unsubscribe_success";

import "./index.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.survey = {};
  }

  set_nav_email = (email) => this.setState({ email });
  set_survey = (survey) => (this.survey = survey);

  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/thetest"
            render={(props) => (
              <Test
                {...props}
                set_nav_email={this.set_nav_email}
                set_survey={this.set_survey}
              />
            )}
          />

          <Route
            path="/yourreport"
            render={(props) => (
              <Report
                {...props}
                email={this.state.email}
                survey={this.survey}
              />
            )}
          />

          <Route path="/consent">
            <Consent />
          </Route>
          <Route path="/rules">
            <Rules />
          </Route>
          <Route path="/email_confirmation/:token/">
            <EmailConfirmation />
          </Route>
          <Route
            path="/results/:token/"
            render={(props) => <Results {...props} />}
          ></Route>
          <Route
            path="/myresults/:token/"
            render={(props) => <Results {...props} share />}
          ></Route>
          <Route path="/training_survey">
            <TrainingSurvey />
          </Route>
          <Route path="/thanks">
            <Thanks />
          </Route>
          <Route path="/email_unsubscribe">
            <EmailUnsubscribe />
          </Route>
          <Route path="/unsubscribe_success">
            <UnsubscribeSuccess />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
