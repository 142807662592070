import React, { Component } from "react";

import Logo from "./assets/logo.png";
import LogoWhite from "./assets/logo_white.png";

class Header extends Component {
  render() {
    let nav = this.props.nav;
    return (
      <div className={`logo-wrap`}>
        <div>
          <img
            src={this.props.red ? LogoWhite : Logo}
            width="150px"
            height="50px"
            alt="Climb 5.13"
            className="logo"
            align="left"
          />
        </div>
        <div className="header-link-wrapper">
          <div
            className={`header-link ${nav === "sport" ? "active" : ""}`}
            onClick={this.props.set_nav("sport")}
          >
            Sport
          </div>
          <div
            className={`header-link ${nav === "boulder" ? "active" : ""}`}
            onClick={this.props.set_nav("boulder")}
          >
            Boulder
          </div>
          <div
            className={`header-link`}
            onClick={() =>
              window.open(
                "https://www.howtoclimb513.com/thesimplextest/",
                "_blank"
              )
            }
          >
            About
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
