import React, { Component } from "react";

class QText extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      value1: "",
      value2: "",
      focused: false,
      focused2: false
    };
  }

  componentDidMount() {
    if (this.props.show_focus) setTimeout(() => this._input.focus(), 1000);
    if (
      this.props.survey_key &&
      this.props.survey[this.props.survey_key] !== 0
    ) {
      this.setState({ value1: this.props.survey[this.props.survey_key] || "" });
    } else if (this.props.answers[this.props.index]) {
      let { value1 = "", value2 = "" } = this.props.answers[this.props.index];
      this.setState({ value1, value2 });
    }
  }

  next_screen = () => {
    this.props.callback(this.state.value1, this.state.value2);
    this.props.handle_next();
  };

  onKeyPress = e => {
    if (e.keyCode === 13) {
      if (this.props.placeholder2 && this.state.focused) {
        this._input2.focus();
      } else if (this.state.focused2 && this.state.value2.length > 0) {
        this.next_screen();
      } else if (
        this.props.validator(this.state.value1) &&
        !this.props.placeholder2
      ) {
        this.next_screen();
      }
    }
  };

  render() {
    // console.log("text state", this.state);
    return (
      <div>
        {this.props.title ? (
          <div className="label2 bold">{this.props.title.toUpperCase()}</div>
        ) : null}
        <div className="label">{this.props.text}</div>
        <div className="flex">
          <input
            type={`${this.props.numeric ? "number" : "text"}`}
            className={`input  ${this.props.email ? "lowercase" : ""}`}
            name={this.props.name}
            ref={inp => {
              this._input = inp;
            }}
            value={this.state.value1}
            placeholder={this.props.placeholder}
            onKeyDown={this.onKeyPress}
            onChange={e => this.setState({ value1: e.target.value })}
            onFocus={() => {
              this.setState({ focused: true });
            }}
            onBlur={() =>
              setTimeout(() => this.setState({ focused: false }), 100)
            }
          />
        </div>
        {(this.props.placeholder2 && this.state.value1.length > 0) ||
        this.props.value2 ? (
          <div className="flex">
            <input
              type={`${this.props.numeric ? "number" : "text"}`}
              className="input"
              name={this.props.name}
              ref={inp => {
                this._input2 = inp;
              }}
              value={this.state.value2}
              placeholder={this.props.placeholder2}
              onKeyDown={this.onKeyPress}
              onChange={e => this.setState({ value2: e.target.value })}
              onFocus={() => this.setState({ focused2: true })}
              onBlur={() =>
                setTimeout(() => this.setState({ focused2: false }), 100)
              }
            />
          </div>
        ) : null}

        {(!this.props.placeholder2 &&
          this.props.validator(this.state.value1)) ||
        (this.state.value1.length > 0 && this.state.value2.length > 0) ? (
          <div className="button-wrap">
            <button className="button" onClick={this.next_screen}>
              NEXT
            </button>
          </div>
        ) : (
          <div className="button-wrap" />
        )}
        {this.props.skip ? <div className="skip"> SKIP </div> : null}
      </div>
    );
  }
}

export default QText;
